import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { connect } from "react-redux";
import {
  update_clause_recommender,
  update_expansion_panel_one,
  update_report_pdf_search,
} from "../../redux/actions";
import {
  modify_table_headers_to_have_links,
  modify_table_header_for_pdf_search,
  modify_table_header_for_recommender,
} from "../common_utils/DataModifiers";
import { ReportStyle } from "../routes/report_generator/component_styles/ReportStyle";
import SingleTable from "./SingleTable";

// needs to update
// needs to retrieve
function ListOfResultTables(props) {
  return (
    <div className="bg-white rounded-lg p-4 shadow-md">
      {Object.keys(props.data_fields).map((key, index) => {
        let { header, title, name } = props.data_fields[key];
        if (props.type === "summary") {
          // For Summary Page
          if (name != "clause") {
            header = modify_table_header_for_pdf_search(
              header,
              props.update_report_pdf_search
            );
          } else {
            header = modify_table_header_for_recommender(header);
          }
        } else {
          // For Reference Document page
          header = modify_table_headers_to_have_links(header);
        }

        var data = props.table_data[key];
        //console.log("data for key" + key + " size: " + data.length)
        if (data.length === 0) {
          return null;
        } else {
          return (
            <div
              style={ReportStyle.expansion_panel_root}
              key={`panel-${index}`}
            >
              <Accordion
                style={{ borderRadius: "1rem", boxShadow: "none" }}
                expanded={props.expansion_panel_toggler.expanded[index]}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => props.update_expansion_panel_one(index)}
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  style={{
                    borderRadius: "2rem",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <div className="bg-slate-100 rounded-full w-full py-3 px-6 font-open-sans text-sm font-semibold text-slate-600">
                    {title}
                  </div>
                </AccordionSummary>
                <AccordionDetails key={key}>
                  <SingleTable
                    data={props.table_data[key]}
                    columns={header}
                    title={title}
                    name={name}
                    type={props.type}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        }
      })}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { table_data, expansion_panel_toggler, reference_doc_store } = state;
  return { table_data, expansion_panel_toggler, reference_doc_store };
};

export default connect(mapStateToProps, {
  update_expansion_panel_one,
  update_report_pdf_search,
})(ListOfResultTables);

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { TDTable } from "tau-design-system";
import { Highlighted } from "./Highlighted";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: { minWidth: 700 },
}));

export default function TDQueryExpansionTable(props) {
  const classes = useStyles();
  const expansion = props.data;
  const [data, setData] = useState([]);
  const paginationData = {
    totalCount: data.length,
    pageSize: 20,
    pageNum: 1,
    startCount: 1,
  };

  const headers = [
    {
      id: "original_words",
      name: "Original Words",
    },
    {
      id: "associated_words",
      name: "Associated Words",
    },
  ];

  useEffect(() => {
    const parsedData = [];

    Object.keys(expansion).forEach((key) => {
      parsedData.push({
        original_words: key,
        associated_words: expansion[key],
      });
    });

    setData(parsedData);
  }, [expansion]);

  return (
    <div className="w-full">
      <TDTable
        data={data}
        headers={headers}
        paginationData={paginationData}
        disablePagination
      />
    </div>
  );
}

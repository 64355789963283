import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_refdoc_data = async () => {
  return await resolve(
    axios.get(API_ADDRESSES["refdoc"]).then((res) => res.data)
  );
};

export const post_refdoc_update = async (data) => {
  return await resolve(
    axios
      .post(API_ADDRESSES["refdoc_update"], { posted_data: data })
      .then((res) => res.data)
  );
};

import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TDDatePicker } from "tau-design-system";
import { update_report_file_attributes } from "../../../../redux/actions";

function DateField(props) {
  //const [selectedDate, setSelectedDate] = useState(props.report_file_attributes['published_date']);
  const property_name = props.name;
  const [selectedDate, setSelectedDate] = useState(
    props.report_file_attributes[property_name]
  );
  const send_to_store = (date) => {
    const momentDate = moment(date);
    const parsed_date = momentDate.format("YYYY-MM-DD");
    setSelectedDate(parsed_date);
    props.update_report_file_attributes({ dateOfSignature: parsed_date });
  };

  const send_to_store_entry = (date) => {
    const momentDate = moment(date);
    const parsed_date = momentDate.format("YYYY-MM-DD");
    setSelectedDate(parsed_date);
    props.update_report_file_attributes({ entryIntoForce: parsed_date });
  };

  return (
    <div>
      <TDDatePicker
        placeholder="No Date Found"
        onChange={
          property_name === "dateOfSignature"
            ? (date) => send_to_store(date)
            : (date) => send_to_store_entry(date)
        }
        value={selectedDate !== null ? new Date(selectedDate) : null}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { report_file_attributes } = state;
  return { report_file_attributes };
};

export default connect(mapStateToProps, { update_report_file_attributes })(
  DateField
);

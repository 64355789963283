import {
  CLEAR_METADATA,
  CLEAR_TABLE_DATA,
  DELETE_REFERENCE_DOC_STORE_SINGLE,
  SET_TOGGLER_DATA_TYPE_REFDOC,
  SET_TOGGLER_DATA_TYPE_REPORT,
  UPDATE_FILE_ATTRIBUTES,
  UPDATE_GRAPH_COMMON_NODES,
  UPDATE_GRAPH_LAYOUT_TYPE,
  UPDATE_GRAPH_LOADING_BOOL,
  UPDATE_GRAPH_METADATA,
  UPDATE_GRAPH_METADATA_BOOL,
  UPDATE_GRAPH_RELATIONSHIP_LENGTH,
  UPDATE_GRAPH_SENSITIVITY_LEVEL,
  UPDATE_METADATA,
  UPDATE_REFERENCE_DOC_STORE,
  UPDATE_REFERENCE_DOC_STORE_SINGLE,
  UPDATE_REPORT_PDF_SEARCH,
  UPDATE_REPORT_PDF_URI,
  UPDATE_STIX_QUERY_DATA,
  UPDATE_SUBGRAPH_METADATA_BOOL,
  UPDATE_TABLE_DATA,
  UPDATE_TOGGLE_EXPANSION_ALL,
  UPDATE_TOGGLE_EXPANSION_MULTIPLE,
  UPDATE_TOGGLE_EXPANSION_ONE,
  UPDATE_GRAPH_HIDE_UNC_BOOL,
  SET_GRAPH_SEARCH_TERM,
  UPDATE_GRAPH_SHOW_SUBGRAPH,
} from "./actionTypes";

export const update_report_file_attributes = (content) => ({
  type: UPDATE_FILE_ATTRIBUTES,
  payload: {
    content,
  },
});

export const update_report_metadata = (content) => ({
  type: UPDATE_METADATA,
  payload: {
    content,
  },
});

export const update_report_table_data = (content) => ({
  type: UPDATE_TABLE_DATA,
  payload: {
    content,
  },
});

export const clear_report_metadata = () => ({
  type: CLEAR_METADATA,
  payload: {},
});

export const clear_table_data = () => ({
  type: CLEAR_TABLE_DATA,
  payload: {},
});

export const update_expansion_panel_all = () => ({
  type: UPDATE_TOGGLE_EXPANSION_ALL,
  payload: {},
});

export const update_expansion_panel_one = (id) => ({
  type: UPDATE_TOGGLE_EXPANSION_ONE,
  payload: {
    id,
  },
});

export const update_expansion_panel_multiple = (ids) => ({
  type: UPDATE_TOGGLE_EXPANSION_MULTIPLE,
  payload: {
    ids,
  },
});

export const set_toggler_data_type_report = () => ({
  type: SET_TOGGLER_DATA_TYPE_REPORT,
  payload: {},
});

export const set_toggler_data_type_refdoc = (ref_doc_dict) => ({
  type: SET_TOGGLER_DATA_TYPE_REFDOC,
  payload: {
    ref_doc_dict,
  },
});

export const update_stix_query_data = (value, scim_name) => ({
  type: UPDATE_STIX_QUERY_DATA,
  payload: {
    value,
    scim_name,
  },
});

export const update_graph_common_nodes = () => ({
  type: UPDATE_GRAPH_COMMON_NODES,
  payload: {},
});

export const update_graph_layout_type = (content) => ({
  type: UPDATE_GRAPH_LAYOUT_TYPE,
  payload: {
    content,
  },
});

export const update_graph_loading_bool = (content) => ({
  type: UPDATE_GRAPH_LOADING_BOOL,
  payload: {
    content,
  },
});

export const update_graph_metadata = (content) => ({
  type: UPDATE_GRAPH_METADATA,
  payload: {
    content,
  },
});

export const update_subgraph_metadata_bool = (
  summary_node,
  sub_node_type,
  bool
) => ({
  type: UPDATE_SUBGRAPH_METADATA_BOOL,
  payload: {
    summary_node,
    sub_node_type,
    bool,
  },
});

export const update_graph_metadata_bool = (summary_node, bool) => ({
  type: UPDATE_GRAPH_METADATA_BOOL,
  payload: {
    summary_node,
    bool,
  },
});

export const update_graph_relationship_length = (length) => ({
  type: UPDATE_GRAPH_RELATIONSHIP_LENGTH,
  payload: {
    length,
  },
});

export const update_graph_sensitivity_level = (sensitivity) => ({
  type: UPDATE_GRAPH_SENSITIVITY_LEVEL,
  payload: {
    sensitivity,
  },
});

export const update_graph_hide_unc_bool = (bool) => ({
  type: UPDATE_GRAPH_HIDE_UNC_BOOL,
  payload: {
    bool,
  },
});

export const set_graph_search_term = (keyword) => ({
  type: SET_GRAPH_SEARCH_TERM,
  payload: {
    keyword,
  },
});

export const update_graph_show_subgraph = (bool) => ({
  type: UPDATE_GRAPH_SHOW_SUBGRAPH,
  payload: {
    bool,
  },
});

export const update_reference_doc_store = (content) => ({
  type: UPDATE_REFERENCE_DOC_STORE,
  payload: {
    content,
  },
});

export const update_reference_doc_store_single = (table_name, content) => ({
  type: UPDATE_REFERENCE_DOC_STORE_SINGLE,
  payload: {
    table_name,
    content,
  },
});

export const delete_reference_doc_store_single = (table_name, index) => ({
  type: DELETE_REFERENCE_DOC_STORE_SINGLE,
  payload: {
    table_name,
    index,
  },
});

export const update_report_pdf_search = (search_value) => ({
  type: UPDATE_REPORT_PDF_SEARCH,
  payload: { search_value },
});

export const update_report_pdf_uri = (pdf_blob) => ({
  type: UPDATE_REPORT_PDF_URI,
  payload: { pdf_blob },
});

import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_statistics_data = async (date_range, no_pub_date_box) => {
  // console.log(date_range)
  return await resolve(
    axios
      .get(API_ADDRESSES["statistics"], {
        params: {
          date_range_dict: date_range,
          no_pub_date_bool: no_pub_date_box,
        },
      })
      .then((res) => res.data)
  );
};

import React, { useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

function SearchDateField(props) {
  const { handleUpdateDate } = props;
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(
    moment("2000-01-01").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const handleStartDateChange = (date) => {
    setStartDate(date);
    let start_date = moment(date).format("YYYY-MM-DD");
    if (start_date === "Invalid date") {
      // when user clears date from datepicker
      start_date = moment("2000-01-01").format("YYYY-MM-DD");
    }
    handleUpdateDate({ start_date });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    let end_date = moment(date).format("YYYY-MM-DD");
    if (end_date === "Invalid date") {
      // when user clears date from datepicker
      end_date = moment().format("YYYY-MM-DD");
    }
    handleUpdateDate({ end_date });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        id="start"
        inputVariant={"outlined"}
        label={"Start Date"}
        helperText="Format: DD/MM/YYYY"
        disableFuture
        clearable
        value={startDate}
        onChange={(date) => handleStartDateChange(date)}
        format="DD/MM/YYYY"
      />
      &nbsp;&nbsp;&nbsp;
      <KeyboardDatePicker
        id="end"
        label={"End Date"}
        helperText="Format: DD/MM/YYYY"
        inputVariant={"outlined"}
        disableFuture
        clearable
        value={endDate}
        onChange={(date) => handleEndDateChange(date)}
        format="DD/MM/YYYY"
      />
    </MuiPickersUtilsProvider>
  );
}

export default SearchDateField;

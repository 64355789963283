import React from "react";
// import { makeStyles } from '@material-ui/core/styles';
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Legend from "../../../../assets/legend.png";

// const useStyles = makeStyles((theme) => ({
//     typography: {
//         padding: theme.spacing(2),
//     },
// }));

export default function LegendPopover() {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "legend" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="primary"
        onClick={handleClick}
        style={{ width: "100%" }}
      >
        Legend
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <img src={Legend} alt="legend" width="457" height="600" />
      </Popover>
    </div>
  );
}

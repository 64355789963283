import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { retrieve_statistics_data } from "../../APIs/dashboard_api";
import { useHistory } from "react-router";
import SearchDateField from "../../common_route_components/SearchDateField";
import _ from "lodash";
import ListOfCharts from "./route_component/ListOfCharts";
import moment from "moment";
import Container from "@material-ui/core/Container";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function DashBoard() {
  const [dates, setDates] = React.useState({
    start_date: moment("2000-01-01").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [checkbox, setCheckbox] = React.useState({
    no_pub_date_box: false,
  });

  const [scim_lists, set_scim_lists] = React.useState(null);
  const history = useHistory();

  const retrieve_stats_data = async () => {
    const { data } = await retrieve_statistics_data(
      dates,
      checkbox.no_pub_date_box
    );

    if (data) {
      const campaign_name_obj = manipulate_data(
        data["campaign_names"],
        "Campaign Name"
      );
      const campaign_name_chart = create_chart_data(campaign_name_obj);

      const malware_name_obj = manipulate_data(
        data["malware_names"],
        "Malware Names"
      );
      const malware_name_chart = create_chart_data(malware_name_obj);

      const threat_actor_obj = manipulate_data(
        data["threat_actors"],
        "Threat Actors"
      );
      const threat_actor_chart = create_chart_data(threat_actor_obj);

      const attack_methods_obj = manipulate_data(
        data["attack_methods"],
        "Attack Methods"
      );
      const attack_methods_chart = create_chart_data(attack_methods_obj);

      const target_verticals_obj = manipulate_data(
        data["target_verticals"],
        "Target Verticals"
      );
      const target_verticals_chart = create_chart_data(target_verticals_obj);

      const vulnerabilities_obj = manipulate_data(
        data["vulnerabilities"],
        "Vulnerabilities"
      );
      const vulnerabilities_chart = create_chart_data(vulnerabilities_obj);

      set_scim_lists({
        campaign_name_chart,
        malware_name_chart,
        threat_actor_chart,
        attack_methods_chart,
        target_verticals_chart,
        vulnerabilities_chart,
      });
    }
  };

  const manipulate_data = (data, title) => {
    let label = [];
    let count = [];
    _.forEach(data, (obj) => {
      label.push(obj["key"]);
      count.push(obj["count"]);
    });

    return { title, label, count };
  };

  const create_chart_data = (data) => {
    let bg_Col, border_Col, hover_bg_Col, hover_border_Col;

    switch (data["title"]) {
      case "Campaign Name":
        bg_Col = "rgb(0, 176, 80)";
        border_Col = "rgb(0, 176, 80)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
        break;
      case "Malware Names":
        bg_Col = "rgb(255, 192, 0)";
        border_Col = "rgb(255, 192, 0)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
        break;
      case "Threat Actors":
        bg_Col = "rgba(192, 0, 0)";
        border_Col = "rgb(192, 0, 0)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
        break;
      case "Attack Methods":
        bg_Col = "rgba(197, 97, 48)";
        border_Col = "rgb(197, 97, 48)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
        break;
      case "Target Verticals":
        bg_Col = "rgba(34, 172, 212)";
        border_Col = "rgb(34, 172, 212)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
        break;
      case "Vulnerabilities":
        bg_Col = "rgba(175, 84, 155)";
        border_Col = "rgb(175, 84, 155)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
        break;
      default:
        bg_Col = "rgba(0, 176, 80)";
        border_Col = "rgb(0, 176, 80)";
        hover_bg_Col = "rgb(255,255,255)";
        hover_border_Col = "rgba(255,255,255,0.5)";
    }
    return {
      labels: data["label"],
      datasets: [
        {
          label: `${data["title"]} - Top 10`,
          backgroundColor: bg_Col,
          borderColor: border_Col,
          borderWidth: 1,
          hoverBackgroundColor: hover_bg_Col,
          hoverBorderColor: hover_border_Col,
          data: data["count"],
        },
      ],
    };
  };

  const handleRedirectLink = (elem) => {
    if (elem.length === 1) {
      // Clicked on a bar of any chart
      let query = elem[0]._model.label;
      let modified_query = modify_query_to_have_quotes(query);
      alert("Searching for: " + modified_query);
      history.push(`/?search=${modified_query}`);
    }
  };

  const modify_query_to_have_quotes = (query) => {
    let splitted_str = query.split(" ");
    if (splitted_str.length > 1) {
      return '"' + query + '"';
    }
    return query;
  };

  const handleUpdateDate = (new_date) => {
    let new_state_date = { ...dates, ...new_date };
    // console.log(new_state_date);
    setDates(new_state_date);
  };

  const handleNoPubDateCheckBox = (event) => {
    setCheckbox({ ...checkbox, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item style={{ backgroundColor: "rgb(255,255,255)" }}>
            <SearchDateField handleUpdateDate={handleUpdateDate} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => retrieve_stats_data()}
            >
              Search
            </Button>
            &nbsp;&nbsp;
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox.no_pub_date_box}
                  onChange={handleNoPubDateCheckBox}
                  name="no_pub_date_box"
                  color="secondary"
                />
              }
              label="Include reports with no Published dates"
            />
          </Grid>
        </Grid>
      </Container>
      <Grid container style={{ backgroundColor: "rgb(57,55,61)" }}>
        <ListOfCharts
          scim_lists={scim_lists}
          handleRedirectLink={handleRedirectLink}
        />
      </Grid>
    </div>
  );
}

export default DashBoard;

import React from "react";
import { TextField } from "@material-ui/core";

export const IPS = "ips";
export const HASHES = "hashes";
export const HASHES_MD5 = "hashes_md5";
export const HASHES_SHA1 = "hashes_sha1";
export const HASHES_SHA256 = "hashes_sha256";
export const URLS = "urls";
export const EMAILS = "emails";
export const COUNTRIES_INVOLVED = "countries_involved";
export const VULNERABILITIES = "vulnerabilities";
export const THREAT_ACTORS = "threat_actors";
export const CAMPAIGN_NAMES = "campaign_names";
export const ATTACK_METHODS = "attack_methods";
export const MALWARE_NAMES = "malware_names";
export const MALWARE_CATEGORIES = "malware_categories";
export const TARGET_VERTICALS = "target_verticals";
export const TTP = "ttp";
export const TTP_EXTRA = "ttp_extra";
export const REGISTRY_KEYS = "registry_keys";
export const DATES_EXTRACTED = "dates_extracted";
export const ALIAS_CHINA = "alias_china";
export const ALIAS_RUSSIA = "alias_russia";
export const WHITE_LIST = "white_list";
export const SUMMARY_REPORT = "summary_report";
export const GOAL_OF_ATTACK = "goal_of_attack";

/*Cadet values*/
export const PREAMBLE = "preamble";
export const CHAPTER = "chapter";
export const PART = "part";
export const MODULE = "module";
export const SECTION = "section";
export const ARTICLE = "article";
export const CLAUSE = "clause";
export const ANNEX = "annex";
export const DOC_META = "doc_meta";

/* Summaries */
export const DATA_DICT = {
  [IPS]: {
    name: IPS,
    title: "IP Address",
    header: [
      { title: "IP", field: "ip" },
      { title: "Country", field: "ip_country" },
      { title: "Identifier", field: "identifier" },
      { title: "Network Type", field: "network_type" },
      { title: "Actor", field: "actor" },
      { title: "Frequency", field: "ip_freq" },
    ],
  },
  [HASHES]: {
    name: HASHES,
    title: "Hashes",
    header: [
      {
        title: "Type",
        field: "is_ssl",
        lookup: { false: "Files", true: "SSL" },
      },
      {
        title: "SHA1",
        field: "sha1",
        editComponent: (props) => (
          <TextField
            type="text"
            defaultValue={props.value}
            style={{ width: "300px" }}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "SHA256",
        field: "sha256",
        editComponent: (props) => (
          <TextField
            type="text"
            defaultValue={props.value}
            style={{ width: "400px" }}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "MD5",
        field: "md5",
        editComponent: (props) => (
          <TextField
            type="text"
            defaultValue={props.value}
            style={{ width: "300px" }}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      { title: "File Name", field: "file_name" },
      { title: "File Size", field: "file_size" },
    ],
  },
  [URLS]: {
    name: URLS,
    title: "URLs",
    header: [
      { title: "URL", field: "url" },
      { title: "Domain", field: "domain" },
      { title: "Identifier", field: "identifier" },
      { title: "Network Type", field: "network_type" },
      { title: "Actor", field: "actor" },
    ],
  },
  [EMAILS]: {
    name: EMAILS,
    title: "Emails",
    header: [
      { title: "Email", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [COUNTRIES_INVOLVED]: {
    name: COUNTRIES_INVOLVED,
    title: "Countries",
    header: [
      {
        title: "Type",
        field: "type",
        lookup: {
          destination: "Destination",
          originator: "Originator",
          mention: "Mention",
        },
      },
      { title: "Country", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [VULNERABILITIES]: {
    name: VULNERABILITIES,
    title: "Vulnerabilities",
    header: [
      { title: "CVE Number", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [THREAT_ACTORS]: {
    name: THREAT_ACTORS,
    title: "Threat Actors",
    header: [
      { title: "Threat Actor", field: "apt" },
      { title: "Alias", field: "alias" },
      { title: "Frequency", field: "count" },
    ],
  },
  [CAMPAIGN_NAMES]: {
    name: CAMPAIGN_NAMES,
    title: "Campaign Names",
    header: [
      { title: "Campaign Name", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [ATTACK_METHODS]: {
    name: ATTACK_METHODS,
    title: "Attack Methods",
    header: [
      { title: "Attack Method", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [MALWARE_NAMES]: {
    name: MALWARE_NAMES,
    title: "Malware Names",
    header: [
      { title: "Malware Name", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [MALWARE_CATEGORIES]: {
    name: MALWARE_CATEGORIES,
    title: "Malware Categories",
    header: [
      { title: "Malware Category", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [TARGET_VERTICALS]: {
    name: TARGET_VERTICALS,
    title: "Target Verticals",
    header: [
      { title: "Industry", field: "name" },
      { title: "Sector", field: "sector" },
      { title: "Frequency", field: "count" },
    ],
  },
  [TTP]: {
    name: TTP,
    title: "Tactics, Techniques and Procedures",
    header: [
      { title: "TTP", field: "name" },
      { title: "Category", field: "category" },
      { title: "ID", field: "id" },
      { title: "Description", field: "description" },
      { title: "Frequency", field: "count" },
    ],
  },
  [REGISTRY_KEYS]: {
    name: REGISTRY_KEYS,
    title: "Registry Keys",
    header: [
      { title: "Registry Key", field: "name" },
      { title: "Frequency", field: "count" },
    ],
  },
  [DATES_EXTRACTED]: {
    name: DATES_EXTRACTED,
    title: "Dates Extracted",
    header: [
      { title: "Date", field: "date" },
      { title: "Date Alias", field: "date_alias" },
    ],
  },
};

/* Reference Documents */
export const REF_DOC_DICT = {
  [ATTACK_METHODS]: {
    name: ATTACK_METHODS,
    title: "Attack Methods",
    header: [{ title: "Name", field: "name" }],
  },
  [CAMPAIGN_NAMES]: {
    name: CAMPAIGN_NAMES,
    title: "Campaign Names",
    header: [
      { title: "Name", field: "name" },
      { title: "Associated Entities", field: "associated_entities" },
    ],
  },
  [MALWARE_CATEGORIES]: {
    name: MALWARE_CATEGORIES,
    title: "Malware Categories",
    header: [{ title: "Name", field: "name" }],
  },
  [MALWARE_NAMES]: {
    name: MALWARE_NAMES,
    title: "Malware Names",
    header: [{ title: "Name", field: "name" }],
  },
  [THREAT_ACTORS]: {
    name: THREAT_ACTORS,
    title: "Threat Actors",
    header: [{ title: "Name", field: "name" }],
  },
  [TTP]: {
    name: TTP,
    title: "TTP Overview",
    header: [
      { title: "Initial Access", field: "initial_access" },
      { title: "Execution", field: "execution" },
      { title: "Persistence", field: "persistence" },
      { title: "Privilege Escalation", field: "privilege_escalation" },
      { title: "Defence Evasion", field: "defence_evasion" },
      { title: "Credential Access", field: "credential_access" },
      { title: "Discovery", field: "discovery" },
      { title: "Lateral Movement", field: "lateral_movement" },
      { title: "Collection", field: "collection" },
      { title: "Command and Control", field: "command_and_control" },
      { title: "Exfiltration", field: "exfiltration" },
      { title: "Impact", field: "impact" },
    ],
  },
  [TTP_EXTRA]: {
    name: TTP_EXTRA,
    title: "TTP - MITRE",
    header: [
      { title: "ID", field: "id" },
      { title: "Name", field: "name" },
      { title: "Description", field: "description" },
    ],
  },
  [TARGET_VERTICALS]: {
    name: TARGET_VERTICALS,
    title: "Target Verticals",
    header: [
      { title: "Sector", field: "sector" },
      { title: "Industry", field: "industry" },
    ],
  },
  [ALIAS_CHINA]: {
    name: ALIAS_CHINA,
    title: "Alias China",
    header: [
      { title: "Mandiant", field: "mandiant" },
      { title: "Crowdstrike", field: "crowdstrike" },
      { title: "Idefense", field: "idefense" },
      { title: "Other Names", field: "other_names" },
    ],
  },
  [ALIAS_RUSSIA]: {
    name: ALIAS_RUSSIA,
    title: "Alias Russia",
    header: [
      { title: "Mandiant", field: "mandiant" },
      { title: "Crowdstrike", field: "crowdstrike" },
      { title: "Idefense", field: "idefense" },
      { title: "Other Names", field: "other_names" },
    ],
  },
  [WHITE_LIST]: {
    name: WHITE_LIST,
    title: "URL and Email White List",
    header: [{ title: "Name", field: "name" }],
  },
};

/* Summaries */
export const CADET_DATA_DICT = {
  [DOC_META]: {
    name: DOC_META,
    title: "Document Details",
    header: [
      { title: "Type", field: "type" },
      { title: "Country", field: "country" },
      { title: "Region", field: "region" },
      { title: "Version", field: "version" },
    ],
  },

  [PREAMBLE]: {
    name: PREAMBLE,
    title: "Preamble",
    header: [{ title: "Content", field: "line_item" }],
  },
  [CHAPTER]: {
    name: CHAPTER,
    title: "Chapter",
    header: [
      { title: "Chapter Number", field: "chapter_number" },
      { title: "Title", field: "chapter_title" },
    ],
  },
  [PART]: {
    name: PART,
    title: "Part",
    header: [
      { title: "Part Number", field: "part_number" },
      { title: "Title", field: "part_title" },
    ],
  },
  [MODULE]: {
    name: MODULE,
    title: "Module",
    header: [
      //{title: 'Type', field: 'type', lookup: { destination: 'Destination', originator: 'Originator', mention: 'Mention'}},
      { title: "Module Number", field: "module_number" },
      { title: "Title", field: "module_title" },
    ],
  },
  [SECTION]: {
    name: SECTION,
    title: "Section",
    header: [
      { title: "Section Number", field: "section_number" },
      { title: "Title", field: "section_title" },
    ],
  },
  [ARTICLE]: {
    name: ARTICLE,
    title: "Article",
    header: [
      { title: "Article Number", field: "article_number" },
      { title: "Title", field: "article_title" },
    ],
  },
  [ANNEX]: {
    name: ANNEX,
    title: "Annex",
    header: [
      { title: "Annex Number", field: "annex_number" },
      { title: "Title", field: "annex_title" },
    ],
  },
  [CLAUSE]: {
    name: CLAUSE,
    title: "Clause",
    header: [{ title: "Clause", field: "line_item", id: "clause_id" }],
  },
};

export const REF_DOC_TABLE_NAMES = [
  ATTACK_METHODS,
  CAMPAIGN_NAMES,
  MALWARE_CATEGORIES,
  MALWARE_NAMES,
  THREAT_ACTORS,
  TTP,
  TTP_EXTRA,
  TARGET_VERTICALS,
  ALIAS_CHINA,
  ALIAS_RUSSIA,
  WHITE_LIST,
];

import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_top_3_similar_clauses = async (clause, clause_id) => {
  return await resolve(
    axios
      .get(API_ADDRESSES["clause_recommender"], {
        params: {
          clause: clause,
          clause_id: clause_id,
        },
      })
      .then((res) => res.data)
  );
};

import React, { useState } from "react";
import { request_autocomplete_suggestions_neo4j } from "../../../APIs/stix_search_api";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { STIX } from "../../../../constants/concept_network_constants";

const useStyles = makeStyles(() => ({
  root: {
    display: "inline-flex",
  },
}));

const CustomisedSearchbar = (props) => {
  const { type, setCurrentVal, setEntitySuggestionsDict } = props;
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const autocomplete_handler = (res) => {
    let dict = {};
    let suggestion_temp = [];
    res.forEach((data) => {
      const entity = data[0];
      const suggestions = data[1];

      suggestions.forEach((suggestion) => {
        dict[suggestion.text] = entity;
        suggestion_temp.push(suggestion);
      });
    });

    // Uncomment this line to enable suggestions
    res && setSuggestions(suggestion_temp);
    setEntitySuggestionsDict(dict);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (type === STIX) {
      const { data } = await request_autocomplete_suggestions_neo4j(value);
      autocomplete_handler(data);
    }
  };

  const renderSuggestion = (suggestion) => suggestion.text;

  const onSuggestionSelected = (e, { suggestionValue }) => {
    // This is where u update the state.
    setValue(suggestionValue);
    setCurrentVal(suggestionValue);
  };

  const inputProps = {
    value: value || "",
    onChange: (e) => {
      setValue(e.target.value);
      setCurrentVal(e.target.value);
    },
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={(e) => onSuggestionsFetchRequested(e)}
        getSuggestionValue={(suggestion) => suggestion.text}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(e, suggestion) =>
          onSuggestionSelected(e, suggestion)
        }
        inputProps={inputProps}
      />
    </div>
  );
};

export default CustomisedSearchbar;

CustomisedSearchbar.propTypes = {
  type: PropTypes.string.isRequired,
  setCurrentVal: PropTypes.func.isRequired,
  setEntitySuggestionsDict: PropTypes.func.isRequired,
};

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CustomisedSearchbar from "./route_component/AutoCompleteSearchBar";
import { SCIM_NEO4J_MAP } from "../../../constants/scim_neo4j_mapping";
import { CONCEPT, STIX } from "../../../constants/concept_network_constants";

const useStyles = makeStyles({
  root: {
    paddingBottom: "30px",
    marginTop: "30px",
  },
});

const ConceptNetworkSearch = () => {
  const classes = useStyles();
  const [currentType, setCurrentType] = useState(CONCEPT);
  const [currentVal, setCurrentVal] = useState("");
  const [entitySuggestionsDict, setEntitySuggestionsDict] = useState({});

  const handleGenerateGraph = () => {
    /*
            open a new tab and display the graph
         */
    if (currentType === CONCEPT) {
      // store the word in localstorage
      localStorage.setItem("topic_model_word", currentVal);
      window.open("/graphsearch?redirect=concept_topic_model", "_blank");
    } else if (currentType === STIX) {
      const entity_type = entitySuggestionsDict[currentVal];

      if (entity_type) {
        let entity_label = _.get(SCIM_NEO4J_MAP[entity_type], "label", "");
        let entity_property = _.get(
          SCIM_NEO4J_MAP[entity_type],
          "property",
          SCIM_NEO4J_MAP[entity_type]["label"]
        );
        let entity_list = [[entity_label, entity_property, currentVal]];
        localStorage.setItem("entity_list", JSON.stringify(entity_list));
        window.open("/graphsearch?redirect=concept_entity", "_blank");
      }
    }
  };

  const handleEntityChange = (e) => {
    setCurrentType(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
            <Select
              native
              value={currentType}
              onChange={handleEntityChange}
              label="Type"
              inputProps={{
                name: "type",
                id: "outlined-type",
              }}
            >
              <option value={CONCEPT}>Concept</option>
              <option value={STIX}>Stix</option>
            </Select>
          </FormControl>

          <CustomisedSearchbar
            type={currentType}
            setCurrentVal={setCurrentVal}
            setEntitySuggestionsDict={setEntitySuggestionsDict}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateGraph}
          >
            View Network
          </Button>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { stix_query_data } = state;
  return { stix_query_data };
};

export default connect(mapStateToProps, {})(ConceptNetworkSearch);

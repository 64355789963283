import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import queryString from "query-string";
import { retrieve_report_details } from "../../APIs/redirect_api";

class ReportRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data_received: false,
      pdf_viewer_state: true,
      data: null,
      search_params: null,
    };
  }

  async make_api_call() {
    const props = this.state.search_params;
    this.setState({ loading: true });
    const parameters = {
      id: props.id,
    };
    try {
      const { data } = await retrieve_report_details(parameters);
      window.open(
        "/report_detail?txt_file_path=" + encodeURIComponent(data),
        "_self"
      );
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  render_document() {
    if (this.state.data_received) {
      return (
        <div>
          {/* <Container id="summary_viewer" style={ReportStyle.container_left}>
                        <br/>
                        <Grid container>
                            <Grid item xs={9} md={9}>
                                <EditableTitleField />
                            </Grid>
                            <Grid item xs={3} md={3} style={ReportStyle.verification_icon_button}>
                                {verified_bool && <VerificationIconButton
                                    summary_fpath={summary_fpath}
                                    summary_id={summary_id}
                                    raw_data={this.state.data}
                                />}
                            </Grid>
                        </Grid>

                        <div style={ReportStyle.published_date}>
                            <label>Published Date: &nbsp;</label>
                            <DateField />
                        </div>

                        <br/>
                        <Grid container>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <h3>
                                    <strong>Extracted Information</strong>
                                </h3>
                            </Grid>
                            <Grid item style={{margin: '10px'}}>
                                <Fab size="small" onClick={() => this.props.update_expansion_panel_all()}>
                                    {this.props.expansion_panel_toggler.expand_all ? <ExpandLessIcon/> : <ExpandMoreIcon />}
                                </Fab>
                            </Grid>
                            <Grid item style={{ marginLeft: 'auto' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => this.pdf_viewer_handler()}
                                >
                                    {this.state.pdf_viewer_state ? 'Close PDF' : 'View PDF'}
                                </Button>
                            </Grid>
                        </Grid>

                        <br/>
                        <ListOfReportTables data_fields={DATA_DICT} type='summary'/>
                        <br/>

                        <GoalOfAttackTextField />
                        <br/>

                        <div style={ReportStyle.button_controls}>
                            <SubmitDialogButton />
                            <span>&nbsp;&nbsp;</span>
                            <DownloadSummaryButton
                                raw_data={this.state.data}
                                summary_fpath={summary_fpath}
                                summary_id={summary_id}
                            />
                            <span>&nbsp;&nbsp;</span>
                            <ExtractSingleFileButton
                                raw_data={this.state.data}
                            />
                        </div>
                    </Container> */}
        </div>
      );
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.location.search)) {
      let search_params = queryString.parse(this.props.location.search);
      if (search_params.summary === "true") {
        search_params = {
          ...search_params,
          summary: true,
        };
      } else {
        search_params = {
          ...search_params,
          summary: false,
        };
      }
      this.setState(
        {
          search_params,
        },
        this.make_api_call
      ); // make the api call after setting state
    }
  }

  render() {
    return (
      <div>
        <div>
          {this.state.loading && <CircularProgress disableShrink={true} />}
        </div>
        {!this.state.loading && this.render_document()}
      </div>
    );
  }
}

export default ReportRedirect;

import { escapeRegExp } from "../../../common_utils/util";

export const Highlighted = ({
  text = "",
  query,
  queryList = [],
  isPartial = true,
}) => {
  let parts = [text];
  let highlights = [...queryList];

  if (!!query) {
    highlights = [{ query, color: "#FFFF00" }, ...highlights];
  }

  highlights.forEach(({ query, color }) => {
    const escapedQuery = escapeRegExp(query);
    const partialQueries = escapedQuery.split(/\s+/);
    const regex = new RegExp(
      `(${isPartial ? partialQueries.join("|") : escapedQuery})`,
      "gi"
    );

    parts = parts
      .map((part) => {
        if (typeof part === "string") {
          return part.split(regex).filter(Boolean);
        }
        return part;
      })
      .flat();

    parts = parts.map((part, i) => {
      if (typeof part === "string") {
        return regex.test(part) ? (
          <mark
            className="px-1 py-px shadow-sm rounded"
            style={{ backgroundColor: color }}
            key={`${part}_${i}`}
          >
            {part}
          </mark>
        ) : (
          part
        );
      }
      return part;
    });
  });

  return parts;
};

// Stix Search
import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";
import {
  COUNTRIES_INVOLVED,
  HASHES_MD5,
  HASHES_SHA1,
  HASHES_SHA256,
  IPS,
  THREAT_ACTORS,
  URLS,
} from "../../constants/tables";
import { SCIM_ELASTIC_MAP } from "../../constants/scim_elasticsearch_mapping";

export const retrieve_stix_data = async (
  queries,
  date_range,
  no_pub_date_box
) => {
  return await resolve(
    axios
      .get(API_ADDRESSES["stix_search"], {
        params: {
          query_entity_dict: queries,
          date_range_dict: date_range,
          no_pub_date_bool: no_pub_date_box,
        },
      })
      .then((res) => res.data)
  );
};

// Stix Search Autocomplete
export const request_autocomplete_root = (value, entity) => {
  return axios.post(API_ADDRESSES["autocomplete"], {
    suggest: {
      suggest: {
        prefix: value,
        completion: {
          field: `${entity}.completion`,
          skip_duplicates: true,
          size: 5,
        },
      },
    },
  });
};

export const request_autocomplete_suggestions = async (value, entity) => {
  return await resolve(
    request_autocomplete_root(value, entity).then(
      (res) => res.data.suggest.suggest[0].options
    )
  );
};

export const request_autocomplete_suggestions_neo4j = async (value) => {
  /*
        This function call all the different entities of stix that is
        being ingested into Elasticsearch and returns all the auto-complete values
        along with its respective entities.

        return [[threat_actor, [data]], ... ]

        Stix Entities in neo4j: 7 of them
        1. threat actors - apt
        2. ips - ip
        3. urls - url
        4. hashes - (hashes) md5, sha1, sha256
        5. countries - country
     */
  const neo4j_entities = [
    THREAT_ACTORS,
    IPS,
    URLS,
    HASHES_MD5,
    HASHES_SHA1,
    HASHES_SHA256,
    COUNTRIES_INVOLVED,
  ];
  const neo4j_entities_mapped = neo4j_entities.map((entity) => {
    return SCIM_ELASTIC_MAP[entity].nested;
  });
  const bulk_autocomplete_suggestions = neo4j_entities_mapped.map((entity) => {
    return request_autocomplete_root(value, entity);
  });
  return await resolve(
    Promise.all(bulk_autocomplete_suggestions).then((res) => {
      return neo4j_entities.map((entity, index) => {
        return [entity, res[index].data.suggest.suggest[0].options];
      });
    })
  );
};

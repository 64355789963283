import DocumentArrowUpIcon from "@heroicons/react/24/solid/DocumentArrowUpIcon";
import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import DocumentMagnifyingGlassIcon from "@heroicons/react/24/solid/DocumentMagnifyingGlassIcon";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { TDSideNav } from "tau-design-system";
import TITANLogo from "../assets/logo.png";
import TAULogo from "../assets/tau_new.png";
import {CLAUSE_RECOMMENDER, HOME_PATH, UPLOAD_PATH, TEXT_SUMMARIZE, TEXT_COMPARISON} from "../constants/path";
import clsx from "clsx";

function Header(props) {
  const { location } = props;

  const menuItems = [
    {
      label: "Intelligent Search",
      icon: <MagnifyingGlassIcon />,
      url: HOME_PATH,
    },
    {
      label: "Upload",
      icon: <DocumentArrowUpIcon />,
      url: UPLOAD_PATH,
    },
    {
      label: "Clause Recommender",
      icon: <SparklesIcon />,
      url: CLAUSE_RECOMMENDER,
    },
    {
      label: "Text Summarize",
      icon: <DocumentTextIcon />,
      url: TEXT_SUMMARIZE,
    },
    {
      label: "Text Comparison",
      icon: <DocumentMagnifyingGlassIcon />,
      url: TEXT_COMPARISON,
    },
  ];

  let defaultPageWidth = "w-28";
  const initialOpen = localStorage.getItem("sidenav-toggle") === "true";

  if (initialOpen) {
    defaultPageWidth = "w-[19.5rem]";
  }

  function onNavToggle(_state) {
    const wrapper = document.getElementById("sidenav--wrapper");

    wrapper.classList.toggle("w-28");
    wrapper.classList.toggle("w-[19.5rem]");
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="h-20 w-full flex flex-row justify-start items-center space-x-6 py-2 px-6 mt-2">
        <img className="h-full w-auto max-h-[3rem]" src={TAULogo} alt="logo" />
        <img
          className="h-full w-auto max-h-[3rem]"
          src={TITANLogo}
          alt="logo"
        />
      </div>
      <div className="flex flex-row space-x-4 w-full h-full">
        <div
          id="sidenav--wrapper"
          className={clsx(
            "z-40 shrink-0 relative h-[calc(100vh-5rem)]",
            defaultPageWidth
          )}
        >
          <div className="fixed h-screen top-0 left-0">
            <TDSideNav
              className="px-1 pt-[5rem] pb-2 h-full"
              menuItems={menuItems}
              onUpdate={onNavToggle}
              currentUrl={location.pathname}
            />
          </div>
        </div>
        <div className="w-full h-auto">{props.children}</div>
      </div>
    </div>
  );
}

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Header);

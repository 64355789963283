import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import DocumentMagnifyingGlassIcon from "@heroicons/react/24/solid/DocumentMagnifyingGlassIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as RadioGroup from "@radix-ui/react-radio-group";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { TDButton, TDIcon, TDTable, TDToggleSwitch } from "tau-design-system";
import { retrieve_top_3_similar_clauses } from "../../APIs/clause_recommender_api";
import { modify_recommended_results } from "../../common_utils/DataModifiers";
import Popup from "reactjs-popup";
import * as ReactDOM from 'react-dom';

const contentStyle = {
  maxWidth: "80%",
  maxHeight: "80%",
  alignSelf: 'center',
  overflow: "auto",
  background: "white",
  borderRadius: "2px",
};
const overlayStyle = {
  backgroundColor: "rgba(0,0,0,0.6)",
};

const node = document.createElement("div");
const popup = (message, {type, timeout}) => {
  document.body.appendChild(node);
  const PopupContent = () => {
    return (
        <Popup type={type} open={true} timeout={timeout} contentStyle={contentStyle} overlayStyle={overlayStyle}>
          <div>
            {message.map((part) => {
              const color = part.added ? "green" : part.removed ? "blue" : "grey";
              return <span style={{ color }}>{part.value} </span>;
            })}
          </div>
        </Popup >
    );
  };


  ReactDOM.render(<PopupContent/>, node);
};

const tableHeaders = [
  {
    name: "AGREEMENT TITLE",
    id: "agreement",
    options: {
      parseValue: (value, rowData) => (
        <a
          target="_blank"
          href={`/report_detail?txt_file_path=txt_utf8%2F${encodeURIComponent(
            rowData["agreement"]
          )}&query=${""}&summary=${false}&file_path=${encodeURIComponent(
            rowData["agreement"]
          )}`}
          rel="noopener noreferrer"
        >
          {value}
        </a>
      ),
    },
  },
  {
    name: "RECOMMENDED CLAUSE",
    id: "clause",
    options: {
      className: "w-5/12",
    },
  },
  {
    name: "COMPARE",
    id: "compare",
    options: {

      parseValue: (value, rowData) => (
          <div>
            <TDButton
                icon={
                  <TDIcon>
                    <DocumentMagnifyingGlassIcon />
                  </TDIcon>
                }
                onClick={() => {popup(rowData["diff"],{type:"info", timeout: 10000})}}
            >
            </TDButton>
          </div>
      ),
    },
  },
  {
    name: "MARK RELEVANCE",
    id: "relevance",
    options: {
      parseValue: (value, rowData) => (
        <div>
          <TDToggleSwitch
            offLabel={<div className="text-2xs">Irrelevant</div>}
            onLabel={<div className="text-2xs">Relevant</div>}
          />
        </div>
      ),
    },
  },
  {
    name: "MARK AS MOST RELEVANT",
    id: "most_relevance",
    options: {
      className: "max-w-[10rem] w-1/12",
      parseValue: (_value, rowData) => (
        <div>
          <RadioGroup.Item
            className="unset-all h-6 w-6 flex flex-row items-center justify-center bg-white rounded-full shadow-md border-2 border-slate-600 group rdx-state-checked:text-white  rdx-state-checked:hover:bg-primary-300 transition-colors rdx-state-unchecked:hover:bg-primary-50 focus:ring-2 focus:ring-primary-200"
            value={rowData.id}
            id={rowData.id}
          >
            <RadioGroup.Indicator className="h-3 w-3 rounded-full rdx-state-checked:hover:bg-primary-200 rdx-state-checked:bg-primary" />
          </RadioGroup.Item>
        </div>
      ),
    },
  },
];

export default function ClauseRecommender() {
  const [relevantItems, setRelevantItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [nomaindata, setNoMainData] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [mostRelevant, setMostRelevant] = useState(null);
  const paginationData = {
    totalCount: data.length || 0,
    pageSize: 20,
    pageNum: 1,
    startCount: 1,
  };

  useEffect(() => {
    if (!!data && data.length > 0) {
      setMostRelevant(data[0].id);
    }
  }, [data]);

  async function recommend(message) {
    try {
      setLoading(true);
      setNoMainData(false);
      setData([]);
      const id = null;
      const { data } = await retrieve_top_3_similar_clauses(message, id);
      setLoading(false);
      if (_.isEmpty(data["clauses"])) {
        setNoMainData(true);
      } else {
        setNoMainData(false);
        setData(modify_recommended_results(data, message));
        console.log("data", modify_recommended_results(data, message));
      }
    } catch (error) {
      setLoading(false);
      setNoMainData(true);
      if (error.response) {
        console.log(error.response);
        console.log("error message: " + error.response.data);
      }
    }
  }

  return (
    <div className="px-4">
      <div>
        <h1 className="font-semibold text-xl">Clause Recommender</h1>
        <div className="text-sm text-slate-500">
          Search the repository for clauses used in similar context documents
        </div>
      </div>
      <div className="flex flex-col items-start justify-start my-8 w-full max-w-screen-2xl">
        <div className="flex flex-col justify-start items-end w-full">
          <textarea
            name="Clause Recommender Search Text"
            id="clause-recommender-text"
            rows="5"
            className="w-full min-w-[42rem] border border-px border-slate-200 rounded-lg font-medium text-sm px-2 py-1.5"
            value={query} // ...force the input's value to match the state variable...
            onChange={(e) => setQuery(e.target.value)} // ... and update the state variable on any edits!
          ></textarea>
          <div className="flex flex-row space-x-2 my-4 justify-end">
            <TDButton variant="ghost" onClick={() => setQuery("")}>
              Clear
            </TDButton>
            <TDButton
              icon={
                <TDIcon>
                  <MagnifyingGlassIcon />
                </TDIcon>
              }
              onClick={() => recommend(query)}
            >
              Recommend
            </TDButton>
          </div>
        </div>
        <div style={{ textAlign: "center" }} className="w-full">
          {/* <br/> */}
          {loading && (
            <CircularProgress
              data-testid="circular-progress"
              disableShrink={true}
            />
          )}
          {nomaindata && <div>No Results Returned</div>}

          {/* <br/> */}
          {!_.isEmpty(data) ? (
            <div className="w-full mt-12">
              <RadioGroup.Root
                defaultValue={mostRelevant}
                onValueChange={setMostRelevant}
              >
                <TDTable
                  className="w-full"
                  data={data}
                  headers={tableHeaders}
                  paginationData={paginationData}
                  onSelectChange={setRelevantItems}
                  disableSearch
                  enableMultiSelect
                ></TDTable>
              </RadioGroup.Root>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_search_data = async (params) => {
  return await resolve(
    axios
      .get(API_ADDRESSES["search"], { params: params })
      .then((res) => res.data)
  );
};

import React from "react";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { update_report_file_attributes } from "../../../../redux/actions";

function GoalOfAttackTextField(props) {
  const [text, setText] = React.useState(
    props.report_file_attributes["goal_of_attack"]
  );

  const handleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const sent_to_store = () => {
    props.update_report_file_attributes({ goal_of_attack: text });
  };

  return (
    <div>
      <TextField
        id="outlined-multiline-static"
        label="Goal of Attack"
        multiline
        rows="4"
        fullWidth={true}
        defaultValue={text}
        onChange={handleChange}
        onBlur={sent_to_store}
        variant="outlined"
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { report_file_attributes } = state;
  return { report_file_attributes };
};

export default connect(mapStateToProps, { update_report_file_attributes })(
  GoalOfAttackTextField
);

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { TDButton } from "tau-design-system";
import store from "../../../../../redux/store";
import { post_report_update } from "../../../../APIs/report_api";
import {
  modify_back_doc_meta,
  modify_back_threat_actor_aliases,
} from "../../../../common_utils/DataModifiers";
import { ReportStyle } from "../../component_styles/ReportStyle";

const preprocess_data = () => {
  const state = store.getState();
  const { table_data, report_file_attributes, report_metadata } = state;
  //let edited_data = modify_back_threat_actor_aliases(table_data);
  let edited_data = modify_back_doc_meta(table_data);
  //console.log(JSON.stringify(report_file_attributes, null, "\t"))
  //console.log(JSON.stringify(table_data, null, "\t"))

  if (edited_data["doc_meta"] !== undefined) {
    edited_data["doc_meta"]["name"] = report_file_attributes["title"];
    edited_data["doc_meta"]["dateOfSignature"] =
      report_file_attributes["dateOfSignature"];
    edited_data["doc_meta"]["entryIntoForce"] =
      report_file_attributes["entryIntoForce"];
    edited_data["doc_meta"]["id"] = report_file_attributes["id"];
  }

  const final_data = {
    data: {
      ...edited_data,
      //...report_file_attributes,
    },
    metadata: {
      ...report_metadata.metadata,
    },
  };
  //console.log(JSON.stringify(final_data, null, "\t"))
  return final_data;
};

export default function SubmitDialogButton() {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [data_to_send, set_data_to_send] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    // generate the json object that you want to send over
    const data = preprocess_data();
    set_data_to_send(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    // post the data to backend
    post_json_obj(data_to_send).then(() => console.log("data sent"));
  };

  const post_json_obj = async (data) => {
    try {
      setLoader(true);
      const response = await post_report_update(data);
      console.log("👉 Returned data:", response);
      if (response.data === 0) {
        setLoader(false);
        window.alert("Changes saved");
        window.location.reload();
      } else {
        setLoader(false);
        window.alert(
          "Unable to save documents. Please try again at a later time."
        );
      }
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
      window.alert(
        "Unable to save documents. Please try again at a later time."
      );
    }
  };

  return (
    <div>
      <div style={ReportStyle.loaderTwo}>
        {loader && <CircularProgress disableShrink={true} />}
      </div>
      <TDButton variant="outline" onClick={handleClickOpen}>
        Save Changes
      </TDButton>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <div className="font-medium font-open-sans text-lg text-slate-700">
            Do you want to submit the changes?
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="font-open-sans text-slate-600">
              Changes will be saved into our database and page will be reloaded
              upon success.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TDButton onClick={handleClose} variant="ghost">
            Disagree
          </TDButton>
          <TDButton onClick={handleSubmit}>Agree</TDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

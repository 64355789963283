import { combineReducers } from "redux";
import report_file_attributes from "./report_file_attributes";
import report_metadata from "./report_metadata";
import table_data from "./table_data";
import expansion_panel_toggler from "./expansion_panel_toggler";
import stix_query_data from "./stix_query_data";
import graph_controls from "./graph_controls";
import reference_doc_store from "./reference_doc_store";
import report_pdf_search_store from "./report_pdf_search_store";

export default combineReducers({
  report_file_attributes,
  report_metadata,
  table_data,
  expansion_panel_toggler,
  stix_query_data,
  graph_controls,
  reference_doc_store,
  report_pdf_search_store,
});

import React, { Component } from "react";
import CytoscapeComponent from "react-cytoscapejs";
import { stylesheet } from "../utils/GraphViewStylesheet";
import {
  avsdf_layout,
  fcose_layout,
  klay_layout,
  euler_layout,
} from "../utils/GraphViewLayout";
import cytoscape from "cytoscape";
import klay from "cytoscape-klay";
import fcose from "cytoscape-fcose";
import avsdf from "cytoscape-avsdf";
import euler from "cytoscape-euler";
import popper from "cytoscape-popper";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { FCOSE, AVSDF, KLAY, EULER } from "../../../../constants/graph_types";
import {
  update_graph_loading_bool,
  update_graph_metadata,
} from "../../../../redux/actions";
import "tippy.js/dist/tippy.css";
import { PopperObj } from "../utils/PopperObj";
import {
  filter_to_show_common_nodes,
  generate_graph_stats,
  modify_unverified_edges,
  remove_edge_from_node,
  remove_selected_sub_nodes_handler,
} from "../utils/GraphViewHelpers";
import GraphSpeedDial from "./SpeedDial";
// import {content_menu} from "../utils/NodeContentMenu";
// import cxtmenu from 'cytoscape-cxtmenu';
cytoscape.use(avsdf);
cytoscape.use(fcose);
cytoscape.use(klay);
cytoscape.use(euler);
cytoscape.use(popper);
// cytoscape.use(cxtmenu);

// Need to update
// Need to read from store
const styles = () => ({
  root: {
    height: "100%",
    display: "flex",
    marginLeft: "auto",
    order: 2,
    width: "calc(100% - 240px)",
  },
});

class GraphView extends Component {
  constructor(props) {
    super(props);
    this.node_map = {};
    this.node_and_edges_store = {};
    this.filtered = null;

    this.state = {
      search_count_result: "",
    };
    this.handleSearchCountChange = this.handleSearchCountChange.bind(this);
  }

  handleSearchCountChange(value) {
    this.setState({ search_count_result: value });
  }

  componentDidMount = () => {
    this.setUpListeners();
  };

  componentDidUpdate = (prevProps) => {
    // https://stackoverflow.com/questions/34506363/deleting-and-recreating-an-element-with-react
    //https://stackoverflow.com/questions/48709127/slider-filtering-edges-by-property-e-g-strength-0-1
    // this.cy.destroy();
    const current_layout = this.layoutHandler(
      this.props.graph_controls.graph_layout
    );

    if (
      this.props.graph_controls.common_nodes_bool !==
      prevProps.graph_controls.common_nodes_bool
    ) {
      if (this.props.graph_controls.common_nodes_bool) {
        this.filtered = filter_to_show_common_nodes(this.cy);
      } else {
        this.filtered.restore();
      }
    }

    if (this.props.data !== prevProps.data) {
      const topic_model_word = sessionStorage.getItem("topic_model_word");
      const cache_key =
        "cache_" +
        topic_model_word +
        "_" +
        this.props.graph_controls.graph_layout +
        "_" +
        this.props.graph_controls.relationship_length +
        "_" +
        this.props.graph_controls.sensitivity_level +
        "_" +
        this.props.graph_controls.hide_unc_node +
        "_" +
        this.props.graph_controls.graph_search_term;
      const search_term = this.props.graph_controls.graph_search_term;

      if (sessionStorage.hasOwnProperty(cache_key)) {
        console.log("cache_key found: " + cache_key);
        this.cy.elements().remove();
        this.cy
          .json({
            elements: JSON.parse(sessionStorage.getItem(cache_key)).elements,
          })
          .layout({
            name: "preset",
            positions: undefined, // map of (node id) => (position obj); or function(node){ return somPos; }
            zoom: undefined, // the zoom level to set (prob want fit = false if set)
            pan: undefined, // the pan level to set (prob want fit = false if set)
            fit: true, // whether to fit to viewport
            padding: 30, // padding on fit
            animate: false, // whether to transition the node positions
            animationDuration: 500, // duration of animation in ms if enabled
            animationEasing: undefined, // easing of animation if enabled
            ready: undefined, // callback on layoutready
            stop: undefined, // callback on layoutstop
          })
          .run();
        this.cy.center();
        this.cy.fit();
        this.props.update_graph_loading_bool(false);
      } else {
        this.cy.elements().remove();
        this.cy.json({ elements: this.props.data });
        this.cy.layout(current_layout).run();
        this.props.update_graph_loading_bool(false);
        this.node_and_edges_store = generate_graph_stats(
          this.cy,
          this.props.update_graph_metadata
        );
        this.cy.center();
        this.cy.fit();
        console.log("save to cache_key: " + cache_key);
        sessionStorage.setItem(cache_key, JSON.stringify(this.cy.json()));
      }

      if (search_term.length > 0) {
        let searched_nodes = this.cy.nodes().filter(function (ele) {
          return ele.data("label").indexOf(search_term.toLowerCase()) >= 0;
        });

        for (let report of searched_nodes) {
          //let summary_report_label = report.data().label;
          this.cy.$("#" + report.data().id).addClass("searchterm");
        }

        if (searched_nodes.length > 1) {
          const search_count_result =
            searched_nodes.length + " nodes have been found.";
          this.handleSearchCountChange(search_count_result);
        } else {
          const search_count_result =
            searched_nodes.length + " node has been found.";
          this.handleSearchCountChange(search_count_result);
        }
      } else {
        this.handleSearchCountChange("");
      }
    }

    if (
      this.props.graph_controls.graph_layout !==
      prevProps.graph_controls.graph_layout
    ) {
      this.cy.layout(current_layout).run();
    }

    if (
      this.props.graph_controls.filterNodes !==
      prevProps.graph_controls.filterNodes
    ) {
      /*
                This function below handles the filtering of nodes via the checkboxes in the UI
             */
      const { metadata: prevMetadata } = prevProps.graph_controls;
      const { filterNodes, metadata } = this.props.graph_controls;
      remove_selected_sub_nodes_handler(
        filterNodes,
        metadata,
        prevMetadata,
        this.node_and_edges_store,
        this.cy
      );
    }

    if (
      this.props.graph_controls.graph_search_term !==
      prevProps.graph_controls.graph_search_term
    ) {
      this.cy.elements().removeClass("searchterm");

      const search_term = this.props.graph_controls.graph_search_term;
      //let searched_nodes = this.cy.filter('node[label="' + search_term.toLowerCase() + '"]');
      if (search_term.length > 0) {
        let searched_nodes = this.cy.nodes().filter(function (ele) {
          return ele.data("label").indexOf(search_term.toLowerCase()) >= 0;
        });

        for (let report of searched_nodes) {
          //let summary_report_label = report.data().label;
          this.cy.$("#" + report.data().id).addClass("searchterm");
        }

        if (searched_nodes.length > 1) {
          const search_count_result =
            searched_nodes.length + " nodes have been found.";
          this.handleSearchCountChange(search_count_result);
        } else {
          const search_count_result =
            searched_nodes.length + " node has been found.";
          this.handleSearchCountChange(search_count_result);
        }
      } else {
        this.handleSearchCountChange("");
      }
    }
  };

  layoutHandler = (layout) => {
    switch (layout) {
      case EULER: {
        return euler_layout;
      }
      case FCOSE: {
        return fcose_layout;
      }
      case AVSDF: {
        return avsdf_layout;
      }
      case KLAY: {
        return klay_layout;
      }
      default:
        return euler_layout;
    }
  };

  componentWillUnmount = () => {
    console.log("remove listeners");
    if (this.cy) {
      this.cy.removeAllListeners();
    }
  };

  setUpListeners = () => {
    this.node_and_edges_store = generate_graph_stats(
      this.cy,
      this.props.update_graph_metadata
    );
    modify_unverified_edges(this.cy);

    this.cy.unbind("click");
    /*
          For click events, place your code here
         */
    this.cy.on("click", "node", (event) => {
      let target = event.target;
      remove_edge_from_node(target, this.node_map, this.cy);
    });
    this.cy.on("mouseover", "node", (event) => {
      let node = event.target;
      let type_str = node.data().type;
      let ref = node.popperRef();
      let dummyDomEle = document.createElement("div");
      this.tip = PopperObj(dummyDomEle, type_str, ref);
      this.tip.show();
    });

    this.cy.on("mouseout", "node", () => {
      this.tip.destroy();
    });

    // this.cy.cxtmenu(content_menu);
    this.cy.on("cxttap", (event) => {
      let node = event.target;
      let node_type = node.data().type;
      let node_label = node.data().label;

      if (node_type === "summary_report") {
        window.open(`/report_detail?pdf_file_path=${node_label}`, "_blank");
      }
    });
    this.cy.ready(() => {
      // when the graph is ready to be shown
      this.props.update_graph_loading_bool(false);
    });
  };

  render() {
    const { classes } = this.props;
    const current_layout = this.layoutHandler(
      this.props.graph_controls.graph_layout
    );

    return (
      <div className={classes.root}>
        <CytoscapeComponent
          elements={CytoscapeComponent.normalizeElements(this.props.data)}
          style={{
            height: "calc(91.3vh)",
            width: "100vw",
            background: "#14151a",
          }}
          layout={current_layout}
          zoom={0}
          minZoom={0}
          maxZoom={4}
          wheelSensitivity={0.2}
          stylesheet={stylesheet}
          cy={(cy) => {
            this.cy = cy;
          }}
        />
        {this.cy && (
          <GraphSpeedDial cy={this.cy} file_list={this.props.file_list} />
        )}

        {this.state.search_count_result !== "" && (
          <div
            style={{
              border: "none",
              position: "absolute",
              bottom: "10px",
              "padding-left": "10px",
            }}
          >
            <input
              className="search_count_display"
              type="text"
              value={this.state.search_count_result}
              onChange={this.handleSearchCountChange}
              readOnly={true}
              style={{
                fontSize: "large",
                border: "none",
                background: "transparent",
                color: "#ffffff",
                width: "100vw",
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { graph_controls } = state;
  return { graph_controls };
};

export default connect(mapStateToProps, {
  update_graph_loading_bool,
  update_graph_metadata,
})(withStyles(styles)(GraphView));

import { STOP_WORDS } from "../../constants/stop_words";

export function sanitizeStopWords(text) {
  const regex = new RegExp("\\b(" + STOP_WORDS.join("|") + ")\\b", "gi");
  const result = text.replace(regex, " ").replace(/\s+/g, " ");
  let escapeRegex = result.replace("-", "");

  if (escapeRegex[0] == " ") {
    escapeRegex = escapeRegex.substring(1);
  }

  return escapeRegex;
}

import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { remove_key_in_list_of_objects } from "../../common_utils/DataModifiers";
import Container from "@material-ui/core/Container";
import {
  post_refdoc_update,
  retrieve_refdoc_data,
} from "../../APIs/reference_doc_api";
import { connect } from "react-redux";
import {
  clear_table_data,
  set_toggler_data_type_refdoc,
  update_expansion_panel_all,
  update_expansion_panel_one,
  update_reference_doc_store,
  update_report_table_data,
} from "../../../redux/actions";
import ListOfReportTables from "../../common_route_components/ListOfResultTables";
import { REF_DOC_TABLE_NAMES } from "../../../constants/tables";
import { TableDictBuilder } from "../../common_utils/TableDictBuilder";
import _ from "lodash";

class ReferenceDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      edited_values: null,
      loading: false,
      data_received: false,
    };
  }

  async make_api_call() {
    try {
      this.setState({ loading: true });
      const { data } = await retrieve_refdoc_data();
      this.props.clear_table_data();
      const ref_doc_dict = TableDictBuilder(data, REF_DOC_TABLE_NAMES);
      this.props.set_toggler_data_type_refdoc(ref_doc_dict);
      this.props.update_reference_doc_store(ref_doc_dict);
      this.props.update_report_table_data(data);
      this.setState({
        data,
        data_received: true,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  async post_edited_values(object) {
    try {
      const response = await post_refdoc_update(object);
      console.log("👉 Returned data:", response);
      if (response.data === 0) {
        window.alert("Changes saved");
        window.location.reload();
      } else
        window.alert(
          "Unable to save documents. Please try again at a later time."
        );
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  }

  render_page() {
    if (this.state.data_received) {
      return (
        <Container>
          <br />
          <h1>Reference Documents</h1>
          <Grid container>
            <Grid item style={{ marginLeft: "auto" }}>
              <Button
                variant="contained"
                onClick={() => this.props.update_expansion_panel_all()}
              >
                {this.props.expansion_panel_toggler.expand_all
                  ? "Close All"
                  : "Open All"}
              </Button>
            </Grid>
          </Grid>

          <br />
          <ListOfReportTables
            data_fields={this.props.reference_doc_store}
            type="reference"
          />
          <br />

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              let data_object = _.cloneDeep({ ...this.props.table_data });
              data_object = remove_key_in_list_of_objects(
                data_object,
                "tableData"
              );
              console.log(data_object);
              if (window.confirm("Submit changes? ")) {
                this.post_edited_values(data_object).then(() =>
                  console.log("data", data_object)
                );
              }
            }}
          >
            Save Changes
          </Button>
          <br />
        </Container>
      );
    }
  }

  componentDidMount() {
    this.make_api_call().then(() => console.log("fetched data from server"));
  }

  render() {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          {this.state.loading && <CircularProgress disableShrink={true} />}
        </div>
        {!this.state.loading && this.render_page()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { table_data, expansion_panel_toggler, reference_doc_store } = state;
  return {
    table_data,
    expansion_panel_toggler,
    reference_doc_store,
  };
};

export default connect(mapStateToProps, {
  update_report_table_data,
  update_expansion_panel_all,
  update_expansion_panel_one,
  update_reference_doc_store,
  set_toggler_data_type_refdoc,
  clear_table_data,
})(ReferenceDocs);

import { UPDATE_FILE_ATTRIBUTES } from "../actionTypes";

const initialState = {
  title: "",
  published_date: "",
  goal_of_attack: "",
};

// Note: no function name!
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILE_ATTRIBUTES: {
      const { content } = action.payload;
      return {
        ...state,
        ...content,
      };
    }
    default:
      return state;
  }
}

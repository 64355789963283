import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_graph_data = async (queries) => {
  return await resolve(
    axios
      .get(API_ADDRESSES["graph_search"], {
        params: {
          queries: queries,
        },
      })
      .then((res) => res.data)
  );
};

export const generate_graph_data_from_stix = async (filenamesList) => {
  return await resolve(
    axios
      .post(API_ADDRESSES["stix_generate_graph"], { filenames: filenamesList })
      .then((res) => res.data)
  );
};

export const generate_graph_data_from_entity = async (
  entityList,
  relationship_length
) => {
  return await resolve(
    axios
      .post(API_ADDRESSES["entity_generate_graph"], {
        entities: entityList,
        relationship_length,
      })
      .then((res) => res.data)
  );
};

export const generate_graph_data_from_topic_word = async (
  word,
  relationship_length,
  sensitivity,
  hide_unc_node,
  show_subgraph,
  graph_search_term
) => {
  return await resolve(
    axios
      .post(API_ADDRESSES["topic_model_generate_graph"], {
        word,
        relationship_length,
        sensitivity,
        hide_unc_node,
        show_subgraph,
        graph_search_term,
      })
      .then((res) => res.data)
  );
};

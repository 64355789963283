import {
  UPDATE_GRAPH_COMMON_NODES,
  UPDATE_GRAPH_LOADING_BOOL,
  UPDATE_GRAPH_METADATA,
  UPDATE_GRAPH_METADATA_BOOL,
  UPDATE_GRAPH_RELATIONSHIP_LENGTH,
  UPDATE_GRAPH_SENSITIVITY_LEVEL,
  UPDATE_SUBGRAPH_METADATA_BOOL,
  UPDATE_GRAPH_HIDE_UNC_BOOL,
  SET_GRAPH_SEARCH_TERM,
  UPDATE_GRAPH_SHOW_SUBGRAPH,
} from "../actionTypes";
import { UPDATE_GRAPH_LAYOUT_TYPE } from "../actionTypes";
import { EULER } from "../../constants/graph_types";
import update from "immutability-helper";

const initialState = {
  common_nodes_bool: false,
  graph_layout: EULER,
  loading_spinner: true,
  metadata: {},
  filterNodes: {},
  relationship_length: null,
  sensitivity_level: 3,
  hide_unc_node: false,
  graph_search_term: "",
  show_subgraph: false,
};

// Note: no function name!
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_GRAPH_COMMON_NODES: {
      return {
        ...state,
        common_nodes_bool: !state.common_nodes_bool,
      };
    }

    case UPDATE_GRAPH_LAYOUT_TYPE: {
      const { content } = action.payload;
      return {
        ...state,
        graph_layout: content,
      };
    }

    case UPDATE_GRAPH_LOADING_BOOL: {
      const { content } = action.payload;
      return {
        ...state,
        loading_spinner: content,
      };
    }

    case UPDATE_GRAPH_METADATA: {
      const { content } = action.payload;
      return {
        ...state,
        metadata: content,
      };
    }

    case UPDATE_SUBGRAPH_METADATA_BOOL: {
      // Add another store to state what summary_node and its subnode has been changed
      const { summary_node, sub_node_type, bool } = action.payload;
      return {
        ...state,
        metadata: update(state.metadata, {
          [summary_node]: {
            [sub_node_type]: {
              $set: !state.metadata[summary_node][sub_node_type],
            },
          },
        }),
        filterNodes: { [summary_node]: { [sub_node_type]: bool } },
      };
    }

    case UPDATE_GRAPH_METADATA_BOOL: {
      const { summary_node, bool } = action.payload;
      let payload = {};
      payload[summary_node] = {};
      for (const [sub_node] of Object.entries(state.metadata[[summary_node]])) {
        payload[summary_node][sub_node] = { $set: bool };
      }
      return {
        ...state,
        metadata: update(state.metadata, payload),
        filterNodes: { [summary_node]: bool },
      };
    }

    case UPDATE_GRAPH_RELATIONSHIP_LENGTH: {
      const { length } = action.payload;
      return {
        ...state,
        relationship_length: length,
      };
    }

    case UPDATE_GRAPH_SENSITIVITY_LEVEL: {
      const { sensitivity } = action.payload;
      return {
        ...state,
        sensitivity_level: sensitivity,
      };
    }

    case UPDATE_GRAPH_HIDE_UNC_BOOL: {
      const { bool } = action.payload;
      return {
        ...state,
        hide_unc_node: bool,
      };
    }

    case SET_GRAPH_SEARCH_TERM: {
      const { keyword } = action.payload;
      return {
        ...state,
        graph_search_term: keyword,
      };
    }

    case UPDATE_GRAPH_SHOW_SUBGRAPH: {
      const { bool } = action.payload;
      return {
        ...state,
        show_subgraph: bool,
      };
    }

    default:
      return state;
  }
}

import { saveAs } from "file-saver";

export const jsonHandler = ({ data, filename }) => {
  let fileToSave = new Blob([JSON.stringify(data)], {
    type: "application/json",
    name: filename,
  });

  try {
    saveAs(fileToSave, filename);
  } catch (e) {
    window.alert("Unable to download Json. Please try again later.");
  }
};

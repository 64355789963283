import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/solid/ClipboardDocumentListIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
const Diff = require("diff");

export default function TextComparison() {

    const [compare, setCompare] = useState("");
    const [baseQuery, setBaseQuery] = useState("");
    const [result, setResult] = useState([{"added": false, "removed": false, "value": ""}]);

    function getDiff(obj, type) {
        const val = obj.target.value;
        let diffResult = [{"added": false, "removed": false, "value": ""}];

        if (type === "query"){

            diffResult = Diff.diffWords(val, compare, {ignoreWhitespace: true, ignoreCase: true});
            setBaseQuery(val);
            setResult(diffResult);
        }
        else{
            diffResult = Diff.diffWords(baseQuery, val, {ignoreWhitespace: true, ignoreCase: true});
            setCompare(val);
            setResult(diffResult);
        }
    }

    const handleOnChange = (event, type) => {
        getDiff(event, type)
    };

    const contentStyle = {
        maxWidth: "100%",
        maxHeight: "80%",
        alignSelf: 'center',
        overflow: "auto",
        background: "white",
        borderRadius: "2px",
        textAlign: "center",
    };
    const textAreaStyle = {
        resize: "none",
        overflow: "auto",
    }

    return (
        <div className="w-full max-w-screen-2xl" style={{maxWidth: "90%"}}>
            <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid container item xs={2} sm={2} justifyContent="center">
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container direction="column" item xs={5} sm={5} justifyContent="center">
                        <h2 className="text-xl font-semibold text-slate-500 mb-2">
                            Base:
                        </h2>
                      <textarea
                          name="Source Text"
                          id="source_text"
                          rows="15"
                          style={textAreaStyle}
                          className="min-w-[42rem] border border-px border-slate-200 rounded-lg font-medium text-sm px-2 py-1.5 w-full max-w-screen-2xl"
                          value={baseQuery}// ...force the input's value to match the state variable...
                          onChange={(e) => handleOnChange(e, "query")}

                      ></textarea>
                    </Grid>

                    <Grid container item xs={1} sm={1} justifyContent="center">
                    </Grid>

                    <Grid container direction="column" item xs={5} sm={5} justifyContent="center">
                        <h2 className="text-xl font-semibold text-slate-500 mb-2">
                            Compare to:
                        </h2>
                      <textarea
                          name="Compare Text"
                          id="compare_text"
                          rows="15"
                          style={textAreaStyle}
                          className="min-w-[42rem] border border-px border-slate-200 rounded-lg font-medium text-sm px-2 py-1.5 w-full max-w-screen-2xl"
                          value={compare} // ...force the input's value to match the state variable...
                          onChange={(e) => handleOnChange(e, "compare")}

                      ></textarea>

                    </Grid>
                </Grid>

                <Grid container item xs={2} sm={2} justifyContent="center">
                </Grid>
                <Grid container direction="column" item xs={11} sm={11} justifyContent="center">
                    <h2 className="text-xl font-semibold text-slate-500 mb-2">
                        Results
                    </h2>
                    <div style={contentStyle}  key="result">
                        <div>
                            {result.map((part, index) => {
                                const color = part.added ? "green" : part.removed ? "blue" : "grey";
                                return <span style={{ color }} key={index}>{part.value}</span>;
                            })}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
/*
                      onChange={(e) => getDiff(e, "query")} // ... and update the state variable on any edits!
 */
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import _, { first, result } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { TDButton, TDIconButton, TDLoader } from "tau-design-system";
import {
  EXCLUDE_HYPERLINK_FIELDS,
  EXCLUDE_PDF_SEARCH_FIELDS,
  UPDATE_CLAUSE_RECOMMENDER,
} from "../../constants/exclude_hyperlink_fields";
import { DOC_META, THREAT_ACTORS } from "../../constants/tables";
import { delete_reference_doc_store_single } from "../../redux/actions";
import { retrieve_top_3_similar_clauses } from "../APIs/clause_recommender_api";
import { ReportStyle } from "../routes/report_generator/component_styles/ReportStyle";
const Diff = require("diff");

export const modify_threat_actor_aliases = (data) => {
  /*
        This function convert ['a', 'b', 'c'] -> 'a,b,c'
     */
  _.forEach(data[THREAT_ACTORS], (threat_actor) => {
    if (!_.isEmpty(threat_actor["alias"])) {
      threat_actor["alias"] = threat_actor["alias"].toString();
    }
  });

  return data;
};

export const modify_back_threat_actor_aliases = (data) => {
  /*
        This function convert 'a,b,c' -> ['a', 'b', 'c']
    */
  _.forEach(data[THREAT_ACTORS], (threat_actor) => {
    try {
      if (!_.isEmpty(threat_actor["alias"])) {
        threat_actor["alias"] = threat_actor["alias"].split(",");
      }
    } catch (e) {
      console.log(e);
    }
  });
  return data;
};

export const modify_doc_meta = (data) => {
  /*
        This function convert ['a', 'b', 'c'] -> 'a,b,c'
     */
  var modified_data = _.cloneDeep(data);
  var doc_meta = modified_data[DOC_META];
  modified_data[DOC_META] = [doc_meta];
  _.forEach(modified_data[DOC_META], (dmeta) => {
    if (!_.isEmpty(dmeta["country"])) {
      dmeta["country"] = dmeta["country"].toString();
    }

    if (!_.isEmpty(dmeta["region"])) {
      dmeta["region"] = dmeta["region"].toString();
    }
  });

  return modified_data;
};

export const modify_back_doc_meta = (data) => {
  /*
        This function convert 'a,b,c' -> ['a', 'b', 'c']
    */
  var modified_data = _.cloneDeep(data);

  var doc_meta = modified_data[DOC_META][0];
  try {
    if (!_.isEmpty(doc_meta["country"])) {
      doc_meta["country"] = doc_meta["country"].split(",");
    }
    if (!_.isEmpty(doc_meta["region"])) {
      doc_meta["region"] = doc_meta["region"].split(",");
    }
  } catch (e) {
    console.log(e);
  }

  modified_data[DOC_META] = doc_meta;

  return modified_data;
};

export const modify_recommended_results = (data, message) => {
  /*
        This function convert ['a', 'b', 'c'] -> 'a,b,c'
     */
  let modified_data = [];
  _.forEach(data["clauses"], (clauseItem) => {
    let modified_item = {
      id: clauseItem["clauseId"][0],
      agreement: clauseItem["filePath"],
      clause: clauseItem["clause"],
      score: clauseItem["score"],
      relevance: true,
      most_relevance: false,
      diff: Diff.diffWords(message, clauseItem["clause"], {ignoreWhitespace: true, ignoreCase: true}),
    };
    modified_data = modified_data.concat(modified_item);
  });

  return modified_data;
};

export const modify_table_headers_to_have_links = (headers) => {
  /*
        _.assign is to merge the two objects together
     */
  _.forEach(headers, (header) => {
    const field = header.field;
    if (!EXCLUDE_HYPERLINK_FIELDS.includes(field)) {
      const hyperlink = {
        render: (rowData) => (
          <Link
            to={{
              pathname: "/",
              search: `?search=${rowData[field]}`,
              state: { redirect: true },
            }}
          >
            {rowData[field]}
          </Link>
        ),
      };
      _.assign(header, hyperlink);
    }
  });
  return headers;
};

export const modify_table_header_for_pdf_search = (headers, dispatcher) => {
  /*
        This function is used in the summary page to modify
        the table header to upload the search term into the redux
        store for pdf searching
     */
  _.forEach(headers, (header) => {
    const field = header.field;
    if (!EXCLUDE_PDF_SEARCH_FIELDS.includes(field)) {
      const searchable_value = {
        render: (rowData) => (
          <div
            style={ReportStyle.table_contents}
            onClick={() => dispatcher(rowData[field])}
          >
            {rowData[field]}
          </div>
        ),
      };
      _.assign(header, searchable_value);
    }
  });

  return headers;
};

// function myFunction(event) {
//     const currentlyVisible = document.querySelector(PopUpStyle.show);
//     if(currentlyVisible) {
//       currentlyVisible.classList.toggle('show');
//     }
//     var popup = event.currentTarget.querySelector(PopUpStyle.popUp);
//     popup.classList.toggle("show");
//   }

export const modify_table_header_for_recommender = (headers) => {
  /*
        This function is used in the summary page to modify
        the table header to upload the search term into the redux
        store for pdf searching
     */
  const node = document.createElement("div");
  const contentStyle = {
    maxWidth: "80%",
    width: "80%",
    height: "76%",
    overflow: "auto",
    background: "white",
    borderRadius: "16px",
  };
  const overlayStyle = {
    backgroundColor: "rgba(0,0,0,0.6)",
  };

  const alert_box = (element_id) => {
    var element = document.getElementById(element_id);
    element.style.display = "inline";
    element.style.fontSize = "12px";
    element.style.backgroundColor = "#424242";
    element.style.color = "#FFFFFF";
    element.style.borderRadius = "16px";
    element.style.padding = "5px";
    element.innerHTML = "Copied!";
    setTimeout(function () {
      element.style.display = "none";
    }, 1000); // 1000ms = 1 second
  };

  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy.replace(/\n\n/g, "\n"));
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "absolute";
      textArea.style.opacity = 0;
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  function ClauseCard({ title, content, filePath }) {
    return (
      <div className="shadow-lg my-8 border border-slate-200 rounded-lg p-6 flex flex-col">
        <div className="flex flex-row space-between w-full items-center">
          <h3 className="w-full font-bold text-md text-slate-600">{title}</h3>
          <div className="flex flex-row space-x-2 justify-center items-center">
            <div id="alert_1"></div>
            <TDIconButton
              btnType="secondary"
              variant="ghost"
              iconSize="xs"
              onClick={() => {
                copyToClipboard(content);
                alert_box("alert_1");
              }}
            >
              <FileCopyOutlinedIcon />
            </TDIconButton>
          </div>
        </div>
        <h4 className="font-medium text-slate-600 text-sm mb-8 mt-2">
          Original File: {filePath}
        </h4>
        <div className="whitespace-pre-line font-regular text-slate-800">
          {content}
        </div>
      </div>
    );
  }

  const popup = (message, id, { type, timeout }) => {
    document.body.appendChild(node);
    const clear = () => {
      ReactDOM.unmountComponentAtNode(node);
      node.remove();
    };

    const popupContent = () => {
      retrieve_top_3_similar_clauses(message, id)
        .then(function (result) {
          if (result["data"]["clauses"].length != 0) {
            var first_clause = result["data"]["clauses"][0]["clause"];
            var first_clause_filePath =
              result["data"]["clauses"][0]["filePath"];
            var second_clause = result["data"]["clauses"][1]["clause"];
            var second_clause_filePath =
              result["data"]["clauses"][1]["filePath"];
            var third_clause = result["data"]["clauses"][2]["clause"];
            var third_clause_filePath =
              result["data"]["clauses"][2]["filePath"];
            const element = (
              <Popup
                lockScroll={true}
                type={type}
                open={true}
                timeout={timeout}
                modal
                contentStyle={contentStyle}
                overlayStyle={overlayStyle}
              >
                <div className="p-6">
                  <h2 className="my-4 font-bold text-slate-500">
                    Top 3 Most Similar Clauses
                  </h2>
                  <div clasName="flex flex-col">
                    <ClauseCard
                      title="Top 1"
                      content={first_clause}
                      filePath={first_clause_filePath}
                    />
                    <ClauseCard
                      title="Top 2"
                      content={second_clause}
                      filePath={second_clause_filePath}
                    />
                    <ClauseCard
                      title="Top 3"
                      content={third_clause}
                      filePath={third_clause_filePath}
                    />
                  </div>
                </div>
              </Popup>
            );
            clear();
            ReactDOM.render(element, node);
          } else {
            const noResult = (
              <Popup
                lockScroll={true}
                type={type}
                open={true}
                timeout={timeout}
                modal
                contentStyle={contentStyle}
                overlayStyle={overlayStyle}
              >
                <div
                  style={{
                    display: "table",
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <span
                    style={{ display: "table-cell", verticalAlign: "middle" }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        clear();
                        document.body.style.overflow = "scroll";
                      }}
                    >
                      Please try another clause
                    </Button>
                  </span>
                </div>
              </Popup>
            );
            clear();
            ReactDOM.render(noResult, node);
          }
        })
        .catch(function (abort_promise) {
          console.log("Aborted Request", abort_promise);
          clear();
        });
    };
    const contentLoader = () => {
      const loader = (
        <Popup
          closeOnEscape={false}
          lockScroll={true}
          type={type}
          open={true}
          timeout={timeout}
          modal
          contentStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "table",
              textAlign: "center",
              maxWidth: "80%",
              width: "80%",
              height: "80%",
              backgroundColor: "#FFFFFF",
              borderRadius: "16px",
              marginRight: "auto",
              marginLeft: "auto",
              transform: "translateY(12.5%)",
            }}
          >
            <div className="flex flex-col h-full w-full items-center justify-center space-y-6">
              <h2 className="font-regular italic text-slate-500">
                Retrieving Similar Clauses...
              </h2>
              <TDLoader />
              <TDButton
                variant="outline"
                onClick={() => {
                  window.stop();
                  document.body.style.overflow = "scroll";
                }}
              >
                Cancel request
              </TDButton>
            </div>
          </div>
        </Popup>
      );
      clear();
      ReactDOM.render(loader, node);
    };
    contentLoader();
    popupContent();
  };

  _.forEach(headers, (header) => {
    const field = header.field;
    const id = header.id;
    if (!UPDATE_CLAUSE_RECOMMENDER.includes(field)) {
      const searchable_value = {
        render: (rowData) => (
          <div
            style={ReportStyle.table_contents}
            onClick={() => {
              popup(rowData[field], rowData[id], {
                type: "info",
                timeout: 1000,
              });
            }}
          >
            {rowData[field]}
          </div>
        ),
      };
      _.assign(header, searchable_value);
    }
  });
  return headers;
};

export const remove_key_in_data_object = (data, key) => {
  let modified_data = _.cloneDeep(data);
  delete modified_data[key];
  return modified_data;
};

export const remove_key_in_list_of_objects = (data, name) => {
  /*
    {
        'alias_russia': [{a:1, b:2, c:3}],
        'alias_china' : ...
        delete c
    }
    */
  let modified_data = data;
  _.forEach(modified_data, (value) => {
    value.forEach((elem) => {
      delete elem[name];
    });
  });

  return modified_data;
};

export const modify_null_values_to_scim_unchanged = (data) => {
  /*
        converts all null type to "scim_unchanged"
   */
  let modified_data = data;
  _.forEach(modified_data, (value, key) => {
    if (_.isEmpty(value)) {
      modified_data[key] = "scim_unchanged";
    }
  });

  return modified_data;
};

export const modify_data_for_matrix = (data, filenames) => {
  /*
        @params data: {entity: [list of filenames that contain the entity]}
        @params filenames: [list of filenames]
        This function converts the params into the data used for react-virtualized
        @returns: A list of list where each list represents a column
     */
  let table_data = [];
  let first_column = [""];
  // first row of data is the entities
  // second row onwards contains [filename, 'x' ... ]
  _.forEach(data, (value, key) => {
    first_column.push(key);
  });
  table_data.push(first_column);
  _.forEach(filenames, (filename) => {
    let column = [filename];
    _.forEach(data, (value) => {
      if (value.includes(filename)) {
        column.push("X");
      } else {
        column.push("");
      }
    });
    table_data.push(column);
  });

  return table_data;
};

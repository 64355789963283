let isObj = (variable) => variable !== null && typeof variable === "object";
// function for checking if an object has a specific nested key
export const objHasKey = (obj, key) => {
  return isObj(obj)
    ? key in obj ||
        Object.values(obj).filter((nestedObj) => objHasKey(nestedObj, key))
          .length > 0
    : false;
};

export const escapeRegExp = (string) => {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
};

import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/24/solid/ChevronUpIcon";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TDButton,
  TDDatePicker,
  TDIcon,
  TDIconButton,
  TDLabelField,
} from "tau-design-system";
import { CADET_DATA_DICT } from "../../../constants/tables";
import {
  clear_report_metadata,
  clear_table_data,
  set_toggler_data_type_report,
  update_expansion_panel_all,
  update_expansion_panel_multiple,
  update_report_file_attributes,
  update_report_metadata,
  update_report_pdf_uri,
  update_report_table_data,
} from "../../../redux/actions";
import {
  retrieve_pdf_blob,
  retrieve_report_summary_data,
} from "../../APIs/report_api";
import ListOfReportTables from "../../common_route_components/ListOfResultTables";
import {
  modify_doc_meta,
  modify_threat_actor_aliases,
  remove_key_in_data_object,
} from "../../common_utils/DataModifiers";
import {
  close_pdf_viewer,
  open_pdf_viewer,
} from "./component_styles/AnimationHandlers";
import { ReportStyle } from "./component_styles/ReportStyle";
import DownloadSummaryButton from "./route_component/buttons/DownloadSummaryButton";
import ExtractSingleFileButton from "./route_component/buttons/ExtractSingleFileButton";
import SubmitDialogButton from "./route_component/buttons/SubmitDialogButton";
import VerificationIconButton from "./route_component/buttons/VerificationIconButton";
import DateField from "./route_component/DateField";
import EditableTitleField from "./route_component/EditableTitleField";
import GoalOfAttackTextField from "./route_component/GoalOfAttackTextField";
import { verify_table_data_is_not_null } from "./utils/DataVerifiers";
import { QueryTabSearcher } from "./utils/QueryTabSearcher";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data_received: false,
      pdf_viewer_state: true,
      data: null,
      search_params: null,
    };
  }

  async make_api_call() {
    let modified_data;
    const props = this.state.search_params;
    this.setState({ loading: true });
    const parameters = {
      txt_file_path: props.txt_file_path,
      pdf_file_path: props.pdf_file_path,
    };
    try {
      const { data } = await retrieve_report_summary_data(parameters);
      //console.log(JSON.stringify(data, null, "\t"))
      modified_data = _.cloneDeep(data);
      //modified_data = modify_threat_actor_aliases(modified_data);
      modified_data = modify_doc_meta(modified_data);
      modified_data = verify_table_data_is_not_null(modified_data);
      const modified_data_without_metadata = remove_key_in_data_object(
        modified_data,
        "metadata"
      );
      this.props.update_report_file_attributes({
        title: data.doc_meta.name,
        dateOfSignature: data.doc_meta.dateOfSignature,
        entryIntoForce: data.doc_meta.entryIntoForce,
        id: data.doc_meta.id,
      });
      this.props.clear_table_data();
      this.props.clear_report_metadata();
      this.props.set_toggler_data_type_report();
      this.props.update_report_table_data(modified_data_without_metadata);
      this.props.update_report_metadata(data.metadata);
      const { data: pdf_blob } = await retrieve_pdf_blob(
        data.metadata.pdf_path
      );
      this.props.update_report_pdf_uri(pdf_blob);
      this.setState({ loading: false, data_received: true, data }, () => {
        QueryTabSearcher(
          this.state.search_params,
          this.props.table_data,
          this.props.update_expansion_panel_multiple
        );
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  pdf_viewer_handler() {
    if (!this.state.pdf_viewer_state) {
      open_pdf_viewer();
    } else {
      close_pdf_viewer();
    }
    this.setState((prevState) => ({
      pdf_viewer_state: !prevState.pdf_viewer_state,
    }));
  }

  render_document() {
    if (this.state.data_received) {
      const verified_bool = this.props.report_metadata.metadata["verified"];
      const { summary_fpath, summary_id } = this.props.report_metadata.metadata;
      const { encoded_uri, search } = this.props.report_pdf_search_store;

      return (
        <div style={ReportStyle.container_wrapper}>
          <Container id="summary_viewer" style={ReportStyle.container_left}>
            <div className="flex flex-row space-x-2 space-between w-full">
              <div className="flex-shrink-[10] my-4 w-full">
                <EditableTitleField />
              </div>
              {verified_bool && (
                <VerificationIconButton
                  summary_fpath={summary_fpath}
                  summary_id={summary_id}
                  raw_data={this.state.data}
                />
              )}
            </div>
            <div className="flex flex-row flex-wrap">
              <TDLabelField className="mr-4 my-1" label="Date of Signature:">
                <DateField name="dateOfSignature" />
              </TDLabelField>
              <TDLabelField className="my-1" label="Entry into Force:">
                <DateField name="entryIntoForce" />
              </TDLabelField>
            </div>
            <br />
            <div className="flex flex-row space-x-4 my-4 justify-between">
              <div className="flex flex-row space-x-4 items-center">
                <h3>
                  <strong>Extracted Information</strong>
                </h3>
                <TDIconButton
                  btnType="secondary"
                  iconSize="s"
                  onClick={() => this.props.update_expansion_panel_all()}
                >
                  {this.props.expansion_panel_toggler.expand_all ? (
                    <TDIcon>
                      <ChevronUpIcon />
                    </TDIcon>
                  ) : (
                    <TDIcon>
                      <ChevronDownIcon />
                    </TDIcon>
                  )}
                </TDIconButton>
              </div>
              <div>
                <TDButton onClick={() => this.pdf_viewer_handler()}>
                  {this.state.pdf_viewer_state ? "Close PDF" : "View PDF"}
                </TDButton>
              </div>
            </div>
            <ListOfReportTables data_fields={CADET_DATA_DICT} type="summary" />
            <div className="flex flex-row space-x-2 flex-wrap mt-6">
              <DownloadSummaryButton
                raw_data={this.state.data}
                summary_fpath={summary_fpath}
                summary_id={summary_id}
              />
              <SubmitDialogButton />
            </div>
          </Container>
          <Container id="pdf_container" style={ReportStyle.container_right}>
            <iframe
              id="pdf_iframe"
              title="pdf"
              style={ReportStyle.pdf_container_iframe}
              src={
                search
                  ? `/pdfjs/web/viewer.html?file=${encoded_uri}&search=${search}`
                  : `/pdfjs/web/viewer.html?file=${encoded_uri}`
              }
              width="100%"
              height="100%"
            />
          </Container>
        </div>
      );
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.location.search)) {
      let search_params = queryString.parse(this.props.location.search);
      if (search_params.summary === "true") {
        search_params = {
          ...search_params,
          summary: true,
        };
      } else {
        search_params = {
          ...search_params,
          summary: false,
        };
      }
      this.setState(
        {
          search_params,
        },
        this.make_api_call
      ); // make the api call after setting state
    }
  }

  render() {
    return (
      <div>
        <div style={ReportStyle.loader}>
          {this.state.loading && <CircularProgress disableShrink={true} />}
        </div>
        {!this.state.loading && this.render_document()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    report_file_attributes,
    table_data,
    report_metadata,
    expansion_panel_toggler,
    report_pdf_search_store,
  } = state;
  return {
    report_file_attributes,
    table_data,
    report_metadata,
    expansion_panel_toggler,
    report_pdf_search_store,
  };
};

export default connect(mapStateToProps, {
  update_report_table_data,
  update_report_metadata,
  update_report_file_attributes,
  update_expansion_panel_all,
  update_expansion_panel_multiple,
  update_report_pdf_uri,
  set_toggler_data_type_report,
  clear_table_data,
  clear_report_metadata,
})(Report);

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as path_constant from "../constants/path";
import ClauseRecommender from "./routes/clause_recommender/ClauseRecommender";
import ConceptNetworkSearch from "./routes/concept_network_search/ConceptNetworkSearch";
import DashBoard from "./routes/dashboard/DashBoard";
import Download from "./routes/download/Download";
import FreeTextSearch from "./routes/freetextsearch/FreeTextSearch";
import Graph from "./routes/graph/Graph";
import NotFound from "./routes/not_found/NotFound";
import ReferenceDocs from "./routes/reference_docs/ReferenceDocuments";
import Report from "./routes/report_generator/Report";
import ReportRedirect from "./routes/report_redirect/ReportRedirect";
import StixSearch from "./routes/stixsearch/StixSearch";
import TextSummarize from "./routes/text_summarize/TextSummarize";
import TextComparison from "./routes/text_comparison/TextComparison";
import Upload from "./routes/upload/Upload";

function Page() {
  return (
    <Switch>
      <Route
        exact
        path={path_constant.HOME_PATH}
        render={(props) => <FreeTextSearch {...props} />}
      />
      <Route
        path={path_constant.REPORT_PATH}
        render={(props) => <Report {...props} />}
      />
      <Route
        path={path_constant.REPORT_REDIRECT_PATH}
        render={(props) => <ReportRedirect {...props} />}
      />
      <Route
        path={path_constant.REF_DOC_PATH}
        render={(props) => <ReferenceDocs {...props} />}
      />
      <Route
        path={path_constant.DASHBOARD_PATH}
        render={(props) => <DashBoard {...props} />}
      />
      <Route
        path={path_constant.STIXSEARCH_PATH}
        render={(props) => <StixSearch {...props} />}
      />
      <Route
        path={path_constant.CONCEPTNETWORK_PATH}
        render={(props) => <ConceptNetworkSearch {...props} />}
      />
      <Route
        path={path_constant.GRAPHSEARCH_PATH}
        render={(props) => <Graph {...props} />}
      />
      <Route
        path={path_constant.UPLOAD_PATH}
        render={(props) => <Upload {...props} />}
      />
      <Route
        path={path_constant.NOT_FOUND}
        render={(props) => <NotFound {...props} />}
      />
      <Route
        path={path_constant.DOWNLOAD}
        render={(props) => <Download {...props} />}
      />
      <Route
        path={path_constant.CLAUSE_RECOMMENDER}
        render={(props) => <ClauseRecommender {...props} />}
      />
      <Route
        path={path_constant.TEXT_SUMMARIZE}
        render={(props) => <TextSummarize {...props} />}
      />
      <Route
          path={path_constant.TEXT_COMPARISON}
          render={(props) => <TextComparison {...props} />}
      />
      <Redirect to={path_constant.NOT_FOUND} />
    </Switch>
  );
}

export default Page;

import React from "react";
import { toast } from "react-toastify";

export const GraphToastEmitter = () => {
  toast.info(
    <div>
      Number of nodes in the graph exceeded rendering limit. <br />
      Only common nodes are displayed.{" "}
    </div>,
    {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

import { DownloadAsJson } from "../../utils/DownloadAsJson";
import React from "react";
import { Button } from "@material-ui/core";
import { remove_key_in_data_object } from "../../../../common_utils/DataModifiers";
import { connect } from "react-redux";
import { extract_single_report } from "../../../../APIs/report_api";

// Retrieve only
function ExtractSingleFileButton(props) {
  const { summary_fpath, verified } = props.report_metadata.metadata;
  const { raw_data } = props;

  const re_extract = async (data_object) => {
    if (verified) {
      const raw_data_without_metadata = remove_key_in_data_object(
        raw_data,
        "metadata"
      );
      DownloadAsJson(summary_fpath, raw_data_without_metadata);
    }

    try {
      const response = await extract_single_report(data_object);
      console.log("👉 Returned data:");
      if (response.data === 0) {
        window.alert("Report has been re-extracted.");
        window.location.reload();
      } else window.alert("Report NOT re-extracted");
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        const obj = { summary_fpath: summary_fpath };
        if (
          window.confirm("Are you sure you want to re-extract this report?? ")
        ) {
          re_extract(obj).then(() => console.log("File re-extracted"));
        }
      }}
    >
      Re-extract this Report
    </Button>
  );
}

const mapStateToProps = (state) => {
  const { report_metadata } = state;
  return { report_metadata };
};

export default connect(mapStateToProps)(ExtractSingleFileButton);

import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import { retrieve_pdf_blob, retrieve_docx_blob } from "../../APIs/report_api";
import queryString from "query-string";
import { saveAs } from "file-saver";

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search_params: null,
    };
  }

  async downloadDocument(filePath) {
    console.log("Download document: " + filePath);
    var pieces = filePath.split("/");
    var filename = pieces[pieces.length - 1];
    console.log("Filename: " + filename);
    if (filePath.endsWith(".pdf")) {
      const { data: pdf_blob } = await retrieve_pdf_blob(filePath);
      if (pdf_blob instanceof Blob) {
        console.log("pdf blob exists");
        saveAs(pdf_blob, filename);
      }
    } else if (filePath.endsWith(".docx")) {
      const { data: docx_blob } = await retrieve_docx_blob(filePath);
      if (docx_blob instanceof Blob) {
        saveAs(docx_blob, filename);
      }
    }
  }

  async make_api_call() {
    let modified_data;
    const props = this.state.search_params;
    const parameters = {
      file_path: props.file_path,
    };
    try {
      this.downloadDocument(parameters.file_path);
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  render_document() {
    return null;
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.location.search)) {
      let search_params = queryString.parse(this.props.location.search);
      this.setState(
        {
          search_params,
        },
        this.make_api_call
      ); // make the api call after setting state
    }
  }

  render() {
    return (
      <div>
        <p>File download is starting now...</p>
      </div>
    );
  }
}

export default Download;

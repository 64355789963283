export const EXCLUDE_HYPERLINK_FIELDS = [
  "ip_freq",
  "file_size",
  "identifier",
  "network_type",
  "count",
  "description",
  "alias",
  "is_ssl",
  "type",
  "other_names",
];

export const EXCLUDE_PDF_SEARCH_FIELDS = [
  "ip_freq",
  "file_size",
  "identifier",
  "network_type",
  "count",
  "description",
  "alias",
  "is_ssl",
  "type",
  "other_names",
  "date",
  "date_alias",
  "category",
  "id",
  "ip_country",
];

export const UPDATE_CLAUSE_RECOMMENDER = [
  "ip_freq",
  "file_size",
  "identifier",
  "network_type",
  "count",
  "description",
  "alias",
  "is_ssl",
  "type",
  "other_names",
  "date",
  "date_alias",
  "category",
  "id",
  "ip_country",
];

import React from "react";
import Grid from "@material-ui/core/Grid";
import { HorizontalBar } from "react-chartjs-2";
import _ from "lodash";

function ListOfCharts(props) {
  const { handleRedirectLink, scim_lists } = props;

  const chartOptions = {
    title: {
      fontColor: "white",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "white",
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "white",
          },
        },
      ],
    },
    legend: {
      labels: {
        // This more specific font property overrides the global property
        fontColor: "white",
      },
    },
  };
  if (!_.isEmpty(scim_lists)) {
    return Object.keys(scim_lists).map((key, index) => {
      return (
        <Grid item xs={6}>
          <HorizontalBar
            data={scim_lists[key]}
            options={chartOptions}
            getElementsAtEvent={(elem) => {
              handleRedirectLink(elem);
            }}
          />
        </Grid>
      );
    });
  } else {
    return null;
  }
}

export default ListOfCharts;

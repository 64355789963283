import JSZip from "jszip";
import _ from "lodash";
import Papa from "papaparse/papaparse.min";
import { saveAs } from "file-saver";

const extract_filename = (summary_file_path) => {
  let filename = summary_file_path.split(
    /summary_json\/|summary_json_edited\//
  )[1];
  filename = filename.replace(".json", "");
  return filename;
};

export const DownloadAsCSV = (summary_fpath, raw_data) => {
  let zip = new JSZip();
  let data_object = { ...raw_data };
  const filename = extract_filename(summary_fpath);
  delete data_object["metadata"];

  try {
    _.forEach(data_object, (value, key) => {
      if (
        key === "title" ||
        key === "published_date" ||
        key === "goal_of_attack"
      ) {
        zip.file(`${key}.txt`, value);
      } else {
        const results = Papa.unparse(value, {
          header: true,
        });
        zip.file(`${key}.csv`, results);
      }
    });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${filename}.zip`);
    });
  } catch (e) {
    window.alert("CSV files NOT downloaded");
  }
};

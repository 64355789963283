import React from "react";
import MaterialTable from "material-table";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { CommonToastContainer } from "../../../common_route_components/CommonToastContainer";
import { CommonToastEmitter } from "../../../common_route_components/CommonToastEmitter";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { tableIcons } from "../../../common_route_components/SingleTableIcons";

// No need to read
// No need to update
function CheckBoxTable(props) {
  const rowsLimit = 50;

  const { data } = props;

  const handleGenerateGraphButton = (data) => {
    // @params data: An array of objects
    // 0: Object { filename: "Mehmet", surname: "Baran", birthYear: 1987, … }
    let file_list = [];
    data.forEach((elem) => {
      file_list.push(elem.filename);
    });
    //https://medium.com/@daverivera90/sharing-state-between-browser-tabs-with-redux-68899eb88fb7
    localStorage.setItem("file_list", JSON.stringify(file_list));
    window.open("/graphsearch?redirect=stix", "_blank");
  };

  // data.tableData.checked=true
  const tableLengthChecker = (rows) => {
    if (rows.length > rowsLimit) {
      // emit the toaster warning
      console.log("emit warning");
      CommonToastEmitter(
        <div>
          Warning: Too many files selected.
          <br />
          Consider selecting 30 files or less to generate graph.
        </div>
      );
    }
  };

  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        title="Select files to visualize"
        columns={[
          {
            title: "Filename(s)",
            field: "filename",
            render: (rowData) => (
              <a
                target="_blank"
                href={`/report_detail?pdf_file_path=${encodeURIComponent(
                  rowData["filename"]
                )}`}
                rel="noopener noreferrer"
              >
                {rowData["filename"]}
              </a>
            ),
          },
          { title: "Published Date", field: "published_date" },
          {
            title: "Verified",
            field: "verified",
            render: (rowData) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rowData["verified"]}
                    name="verified-checkbox"
                    color="primary"
                  />
                }
                label=""
              />
            ),
          },
        ]}
        data={data}
        options={{
          selection: true,
        }}
        actions={[
          {
            tooltip: "Generate Graph",
            icon: () => <InsertChartIcon />,
            onClick: (evt, data) => handleGenerateGraphButton(data),
          },
        ]}
        onSelectionChange={(rows) => tableLengthChecker(rows)}
      />
      <CommonToastContainer />
    </div>
  );
}

export default CheckBoxTable;

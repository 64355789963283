import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_report_details = async (params) => {
  return await resolve(
    axios
      .get(API_ADDRESSES["report_redirect"], { params: params })
      .then((res) => res.data)
  );
};

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import _ from "lodash";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useState } from "react";
import { TDButton } from "tau-design-system";
import {
  process_data,
  retrieve_completed_ocr_data,
  retrieve_pending_data,
  upload_pdf_zip,
} from "../../APIs/upload_api";
import { CommonToastContainer } from "../../common_route_components/CommonToastContainer";
import { CommonToastEmitter } from "../../common_route_components/CommonToastEmitter";
import Loader from "../../common_route_components/Loader";
import CustomisedTreeView from "./route_components/CustomisedTreeView";

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    dropzone: {
      margin: 25,
    },
    uploadButton: {
      margin: "0px 25px 20px",
    },
    processButton: {
      width: "80%",
      margin: "25px 0px 0px",
    },
    progressBar: {
      margin: "0px 0px 0px 25px",
    },
    pendingOCR: {
      width: "80%",
      height: 250,
      border: 3,
      borderStyle: "dashed",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderRadius: 4,
      padding: theme.spacing(2),
      overflow: "auto",
      boxSizing: "border-box",
    },
    completedOCR: {
      height: 400,
      width: "60%",
      padding: theme.spacing(2),
      border: 3,
      borderStyle: "dashed",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderRadius: 4,
      overflow: "auto",
      boxSizing: "border-box",
    },
    loader: {
      position: "absolute",
      width: "50%",
      height: 250,
      top: "50%",
      left: "50%",
      boxSizing: "border-box",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

const Upload = () => {
  const classes = useStyles();
  const [fileObjects, setFileObjects] = useState([]);
  const [progress, setProgress] = useState(1);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [processStatus, setProcessStatus] = useState(false);
  const [pendingJson, setPendingJson] = useState({});
  const [completedOCRJson, setCompletedOCRJson] = useState({});

  const get_pending_data = async () => {
    return await retrieve_pending_data();
  };

  const get_completed_ocr_data = async () => {
    return await retrieve_completed_ocr_data();
  };

  React.useEffect(() => {
    // Retrieve data for pending
    get_pending_data().then((res) => setPendingJson(res.data));
    get_completed_ocr_data().then((res) => setCompletedOCRJson(res.data));
  }, []);

  React.useEffect(() => {
    if (progress === 100) {
      // Fetch pending ocr data from the server
      //get_pending_data().then(res => setPendingJson(res.data));
    }
  }, [progress]);

  const handleSubmitToBackend = async () => {
    if (fileObjects.length > 0) {
      for (var i = fileObjects.length - 1; i > -1; i--) {
        setLoaderStatus(true);
        setProgress(1);
        let formData = new FormData();
        formData.append("file", fileObjects[i].file);
        await upload_pdf_zip(formData, setProgress, setLoaderStatus);
        const newFileObjs = [...fileObjects];
        newFileObjs.splice(-1, 1);
        setFileObjects(newFileObjs);
      }
      setFileObjects([]);
      get_pending_data().then((res) => setPendingJson(res.data));
    }
  };

  const handleSubmitToProcess = async () => {
    setProcessStatus(true);
    await process_data();
    get_pending_data().then((res) => setPendingJson(res.data));
    setProcessStatus(false);
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid container direction="column" item xs={5} sm={5} justify="center">
          <div className={classes.dropzone}>
            <h2 className="text-xl font-semibold text-slate-500 mb-2">
              Upload
            </h2>
            <DropzoneAreaBase
              filesLimit={20}
              fileObjects={fileObjects}
              onAdd={(newFileObjs) => {
                setFileObjects([].concat(fileObjects, newFileObjs));
              }}
              onDelete={(deleteFileObj, index) => {
                const newFileObjs = [...fileObjects];
                newFileObjs.splice(index, 1);
                setFileObjects(newFileObjs);
              }}
              maxFileSize={5000000000000}
              dropzoneText={"Click here or Drag and Drop Files (Zip preferred)"}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview={true}
              previewGridProps={{ container: { spacing: 1, direction: "row" } }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
            />
          </div>
          <TDButton
            disabled={processStatus}
            className={classes.uploadButton}
            onClick={() => handleSubmitToBackend()}
          >
            Upload
          </TDButton>
          {/*Upload Progress Bar*/}
          {loaderStatus ? (
            <div className={classes.progressBar}>
              <Loader progress={progress} />
            </div>
          ) : null}
        </Grid>

        <Grid container item xs={2} sm={2} justify="center">
          <ArrowForwardIosIcon fontSize="large" color="primary" />
        </Grid>

        <Grid container direction="column" item xs={5} sm={5} justify="center">
          <h2 className="text-xl font-semibold text-slate-500 mb-2">
            Files to be processed
          </h2>
          <div className={classes.pendingOCR}>
            {!_.isEmpty(pendingJson) && (
              <CustomisedTreeView data={pendingJson} />
            )}
          </div>
          <TDButton
            disabled={processStatus}
            className={classes.processButton}
            onClick={() => handleSubmitToProcess()}
          >
            Process
          </TDButton>

          {processStatus ? (
            <div className={classes.loader}>
              <CircularProgress disableShrink={true} />
            </div>
          ) : null}
        </Grid>
      </Grid>
      <CommonToastContainer />
    </div>
  );
};

export default Upload;

/*
            CommonToastEmitter(
                <div>
                    File has been uploaded!
                </div>
            );
                 <Grid container item xs={2} sm={2} justify="center">
                    <ArrowForwardIosIcon fontSize="large" color="primary"/>
                </Grid>

                <Grid container direction="column" item xs={5} sm={5} justify="center">
                    <Typography variant="h5">
                        Pending OCR
                    </Typography>
                    <div className={classes.pendingOCR}>
                        {!_.isEmpty(pendingJson) && <CustomisedTreeView data={pendingJson}/>}
                    </div>
                </Grid>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography variant="h5">
                        Completed OCR
                    </Typography>
                    <div className={classes.completedOCR}>
                        {!_.isEmpty(completedOCRJson) && <CustomisedTreeView data={completedOCRJson}/>}
                    </div>
                </Grid>
 */

import { Button } from "@material-ui/core";
import React from "react";
import { TDButton } from "tau-design-system";
import verified_badge from "../../../../../assets/verified-badge.png";
import { unverify_report } from "../../../../APIs/report_api";
import { remove_key_in_data_object } from "../../../../common_utils/DataModifiers";
import { DownloadAsJson } from "../../utils/DownloadAsJson";

// No need to update
// Need to retrieve raw_data ...
export default function VerificationIconButton(props) {
  const { summary_fpath, raw_data, summary_id } = props;

  const send_unverify = async (summary_data) => {
    const raw_data_without_metadata = remove_key_in_data_object(
      raw_data,
      "metadata"
    );

    // All changes made will be lost, as all the changes are not saved!
    DownloadAsJson(summary_fpath, raw_data_without_metadata);

    try {
      const response = await unverify_report(summary_data);
      if (response.data === 0) {
        window.alert("Changes saved");
        window.location.reload();
      } else window.alert("Please try again later.");
    } catch (e) {
      console.log(`😱 Axios request failed: ${e}`);
    }
  };

  return (
    <div className="flex flex-row space-x-1 items-center flex-shrink">
      <img width="40" height="40" src={verified_badge} alt={"blue tick"} />
      <TDButton
        variant="outline"
        paddingSize="s"
        onClick={() => {
          const summary_data = { summary_fpath, summary_id };
          if (window.confirm("Would you like to deverify this document? ")) {
            send_unverify(summary_data).then(() =>
              console.log("deverify document.")
            );
          }
        }}
      >
        Deverify
      </TDButton>
    </div>
  );
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import {
  update_graph_common_nodes,
  update_graph_layout_type,
  update_graph_metadata_bool,
  update_graph_relationship_length,
  update_graph_sensitivity_level,
  update_subgraph_metadata_bool,
  update_graph_hide_unc_bool,
  set_graph_search_term,
  update_graph_show_subgraph,
} from "../../../../redux/actions";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {
  AVSDF,
  KLAY,
  EULER,
  STIX_GRAPH,
  CONCEPT_NETWORK_TOPIC_MODEL,
  CONCEPT_NETWORK_STIX,
} from "../../../../constants/graph_types";
import LegendPopover from "./LegendPopover";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    marginTop: 64,
    height: "calc(100% - 64px)",
    // backgroundColor: '#FBF6F6'
    backgroundColor: "#1e1e1e",
  },
  root: {
    color: "#a6a6a6",
    // backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0), // top, bottom 2 - left, right 0
    background: "#3a3d41",
  },
  header: {
    margin: `0 0 ${theme.spacing(2)}px ${theme.spacing(1)}px`, // bottom, left - 2
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "#a6a6a6",
    },
  },
  graphControlRoot: {
    // backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    margin: `0 0 0 0 px`,
  },
  graphControlType: {
    margin: `0 0 0 ${theme.spacing(2)}px`,
  },
  graphControlSummaryText: {
    width: 150,
    overflowWrap: "break-word",
  },

  mark: {
    color: "#a6a6a6",
  },

  icon: {
    fill: "#a6a6a6",
  },

  select: {
    "&:before": {
      borderColor: "#a6a6a6",
    },
    "&:after": {
      borderColor: "#a6a6a6",
    },
  },
}));

// Need to update
// No need to read from store
const Sidebar = (props) => {
  const classes = useStyles();
  const { currentGraphType, disabled } = props;
  const { metadata } = props.graph_controls;
  const [commonNodesCB, setCommonNodesCB] = useState(false);
  const [graphType, setGraphType] = React.useState(EULER);
  const [hideUNCNodes, setHideUNCNodes] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSubGraph, setShowSubGraph] = useState(false);

  const handleLayoutChange = (event) => {
    const graph_type = event.target.value;
    setGraphType(graph_type);
    props.update_graph_layout_type(graph_type);
  };

  const handle_common_nodes_cb = (event) => {
    setCommonNodesCB(event.target.checked);
    props.update_graph_common_nodes();
  };

  const onChangeSummary = (event, summary_node) => {
    props.update_graph_metadata_bool(summary_node, event.target.checked);
  };

  const onChangeSub = (event, summary_node, sub_node_type) => {
    props.update_subgraph_metadata_bool(
      summary_node,
      sub_node_type,
      event.target.checked
    );
  };

  const valuetext = (value) => {
    return value;
  };

  const handleRelationshipLengthSlider = (value) => {
    props.update_graph_relationship_length(value);
  };

  const handleSensitivityLevelSlider = (value) => {
    props.update_graph_sensitivity_level(value);
  };

  const handleHideUncNodeCheckbox = (event) => {
    setHideUNCNodes(event.target.checked);
    props.update_graph_hide_unc_bool(event.target.checked);
  };

  const handleSetSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    props.set_graph_search_term(event.target.value);
  };

  const handleSubGraphButtonClick = () => {
    let toggleResult = showSubGraph;

    if (toggleResult) {
      console.log();
      toggleResult = false;
    } else {
      toggleResult = true;
    }

    setShowSubGraph(toggleResult);
    props.update_graph_show_subgraph(toggleResult);
  };

  const sensitivityMarks = [
    {
      value: 1,
      label: "Low",
    },
    {
      value: 2,
      label: "Medium",
    },
    {
      value: 3,
      label: "High",
    },
  ];

  const graph_control_switcher = (disabled) => {
    return (
      <div className={classes.root}>
        <LegendPopover />
        <Divider className={classes.divider} />
        <FormControl className={classes.formControl}>
          <InputLabel
            shrink
            id="demo-simple-select-placeholder-label-label"
            style={{ color: "#707070" }}
          >
            Layout Type
          </InputLabel>
          <Select
            labelId="graph-layout-select"
            id="graph-layout-select"
            value={graphType}
            onChange={handleLayoutChange}
            style={{ color: "#a6a6a6" }}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            className={classes.select}
          >
            <MenuItem value={EULER}>Network</MenuItem>
            <MenuItem value={KLAY}>Tree</MenuItem>
            <MenuItem value={AVSDF}>Circle</MenuItem>
          </Select>
        </FormControl>
        <Divider className={classes.divider} />

        {(currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL ||
          currentGraphType === CONCEPT_NETWORK_STIX) && (
          <div>
            <Typography
              id="discrete-slider"
              className={classes.header}
              display="block"
              //    color="primary"
            >
              Relationship Length
            </Typography>
            <Slider
              onChangeCommitted={(e, value) =>
                handleRelationshipLengthSlider(value)
              }
              defaultValue={
                currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL ? 1 : 1
              }
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              step={1}
              valueLabelDisplay="auto"
              marks
              min={1}
              max={currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL ? 4 : 2}
              style={{ color: "#a6a6a6" }}
            />
          </div>
        )}

        {currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL && (
          <div>
            <Typography
              id="discrete-slider"
              className={classes.header}
              display="block"
              //    color="primary"
            >
              Confidence Level
            </Typography>
            <Slider
              onChangeCommitted={(e, value) =>
                handleSensitivityLevelSlider(value)
              }
              defaultValue={3}
              getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              step={1}
              valueLabelDisplay="auto"
              marks={sensitivityMarks}
              min={1}
              max={3}
              classes={{ markLabel: classes.mark }}
              style={{ color: "#a6a6a6" }}
            />
          </div>
        )}

        {currentGraphType === STIX_GRAPH && (
          <div>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={commonNodesCB}
                    onChange={handle_common_nodes_cb}
                    name="commonNodesCB"
                    color="primary"
                    disabled={disabled}
                  />
                }
                label="Show Common Nodes Only"
              />
            </FormGroup>
            <Divider className={classes.divider} />
            <Typography
              className={classes.header}
              color="primary"
              display="block"
              variant="h6"
            >
              Reports
            </Typography>
          </div>
        )}

        {currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL && (
          <div>
            <Divider className={classes.divider} />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideUNCNodes}
                    onChange={handleHideUncNodeCheckbox}
                    name="hideUNCNode"
                    //    color="primary"
                    disabled={disabled}
                    style={{ color: "#a6a6a6" }}
                  />
                }
                label="Hide Unc Nodes"
              />
            </FormGroup>
          </div>
        )}

        {currentGraphType === CONCEPT_NETWORK_TOPIC_MODEL && (
          <div>
            <Divider className={classes.divider} />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSetSearchTerm}
              placeholder="Search for node"
              style={{
                width: "199px",
                fontSize: "large",
                background: "#a6a6a6",
                color: "#ffffff",
              }}
            />

            <Button
              variant="outlined"
              onClick={handleSubGraphButtonClick}
              style={{
                width: "100%",
                color: "#a6a6a6",
                borderColor: "#a6a6a6",
              }}
            >
              Graph / Subgraph
            </Button>
          </div>
        )}

        {Object.entries(metadata).map(([summary_node, entities]) => {
          let allFalse = Object.keys(entities).every(function (k) {
            return entities[k] === false;
          });
          return (
            <div className={classes.graphControlRoot} key={summary_node}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!allFalse}
                    name="checkedB"
                    color="primary"
                    onChange={(event) => onChangeSummary(event, summary_node)}
                  />
                }
                label={
                  <Typography
                    className={classes.graphControlSummaryText}
                    variant="body2"
                    color="textSecondary"
                  >
                    {summary_node}
                  </Typography>
                }
              />
              {Object.entries(entities).map(([entity, bool]) => {
                return (
                  <FormControl key={`${summary_node}-${entity}`}>
                    <FormControlLabel
                      className={classes.graphControlType}
                      control={
                        <Checkbox
                          checked={bool}
                          name="checkedB"
                          color="secondary"
                          onChange={(event) =>
                            onChangeSub(event, summary_node, entity)
                          }
                        />
                      }
                      label={
                        <Typography variant="body2" color="textSecondary">
                          {entity}
                        </Typography>
                      }
                    />
                  </FormControl>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      open={true}
      variant="persistent"
    >
      {graph_control_switcher(disabled)}
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  const { graph_controls } = state;
  return { graph_controls };
};

export default connect(mapStateToProps, {
  update_graph_common_nodes,
  update_graph_layout_type,
  update_subgraph_metadata_bool,
  update_graph_metadata_bool,
  update_graph_relationship_length,
  update_graph_sensitivity_level,
  update_graph_hide_unc_bool,
  set_graph_search_term,
  update_graph_show_subgraph,
})(Sidebar);

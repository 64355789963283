import { UPDATE_STIX_QUERY_DATA } from "../actionTypes";

const initialState = {};

// Note: no function name!
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_STIX_QUERY_DATA: {
      const { value, scim_name } = action.payload;
      return {
        ...state,
        [scim_name]: value,
      };
    }

    default:
      return state;
  }
}

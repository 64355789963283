import * as RadioGroup from "@radix-ui/react-radio-group";
import { clsx } from "clsx";
import React from "react";
import { TDRadioButtonProps } from "./TDRadioButton.types";

export default function TDRadioButton({ id, value }: TDRadioButtonProps) {
  const rootClassName =
    "RadioGroupItem h-6 w-6 flex flex-row items-center justify-center bg-white rounded-full shadow-md border border-slate-400 group rdx-state-checked:text-white  rdx-state-checked:hover:bg-primary-300 transition-colors rdx-state-unchecked:hover:bg-primary-50 focus:ring-2 focus:ring-primary-200";

  return (
    <RadioGroup.Item className={clsx(rootClassName)} id={id} value={value}>
      <RadioGroup.Indicator className="h-3 w-3 rounded-full rdx-state-checked:hover:bg-white rdx-state-checked:bg-primary" />
    </RadioGroup.Item>
  );
}

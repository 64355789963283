import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { connect } from "react-redux";
import { TDTextField } from "tau-design-system";
import { update_report_file_attributes } from "../../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 400,
  },
}));

function EditableTitleField(props) {
  const [title, setTitle] = React.useState(
    props.report_file_attributes["title"]
  );
  const classes = useStyles();

  const handleChange = (val) => {
    setTitle(val);
  };

  const send_to_store = () => {
    props.update_report_file_attributes({ title: title });
  };

  return (
    <TDTextField
      id="filled-full-width"
      placeholder="Title"
      value={title}
      onUpdate={handleChange}
      onBlur={send_to_store}
    />
  );
}

const mapStateToProps = (state) => {
  const { report_file_attributes } = state;
  return { report_file_attributes };
};

export default connect(mapStateToProps, { update_report_file_attributes })(
  EditableTitleField
);

import MaterialTable from "material-table";
import React, { useState } from "react";
import { connect } from "react-redux";
// import { makeStyles } from '@material-ui/core/styles';
import {
  delete_reference_doc_store_single,
  update_reference_doc_store_single,
  update_report_table_data,
} from "../../redux/actions";
// import AddRowAction from "./AddRowAction";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import { titleLower } from "../common_utils/TableDictBuilder";
import { tableIcons } from "./SingleTableIcons";
//import { useRef } from 'react';

// needs to update
// NO need to read

// const useStyles = makeStyles((theme) => ({
//     form: {
//         display: 'flex',
//         flexDirection: 'column',
//         margin: 'auto',
//         width: 'fit-content',
//     },
//     formControl: {
//         marginTop: theme.spacing(2),
//         minWidth: 120,
//     },
//     formControlLabel: {
//         marginTop: theme.spacing(1),
//     },
// }));

function SingleTable(props) {
  /*
        This function is used for reference docs and summary page
        to generate the tables used in the drop down tabs.
        The dialog feature only works in the reference documents for
        the time being.
    */
  const { columns, name } = props;
  const [data, setData] = useState(props.data || []);
  const [openDialog, setOpenDialog] = React.useState(false);
  let column_field;

  const handleDeleteColumn = (index, field) => {
    props.delete_reference_doc_store_single(name, index);
    let oldData = [...data];
    for (let itemObj of oldData) {
      delete itemObj[field];
    }
    let dataDelete = oldData.filter(
      (itemObj) => Object.keys(itemObj)[0] !== "tableData"
    );
    props.update_report_table_data({
      [name]: dataDelete,
    });
    setData([...dataDelete]);
  };

  const handleCreateNewColumn = () => {
    const new_column_name = column_field.value;
    const new_column = [
      ...columns,
      {
        title: new_column_name,
        field: titleLower(new_column_name),
      },
    ];
    props.update_reference_doc_store_single(name, new_column);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <pre style={{ fontFamily: "inherit", whiteSpace: "pre-line" }}>
        <MaterialTable
          icons={tableIcons}
          title=""
          columns={columns}
          data={data}
          options={{
            draggable: true,
            columnsButton: false,
            //paging:false,
            //pageSize:data.length<5 ? data.length : 5,       // make initial page size
            pageSize: 5,
            emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
            pageSizeOptions: [5, 10, 20, 50], // rows selection options
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  {
                    const updated_data = [...data, newData];
                    props.update_report_table_data({
                      [name]: updated_data,
                    });
                    setData(updated_data);
                  }
                  resolve();
                }, 500);
              }),
            onBulkUpdate: (changes) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const dataUpdate = [...data];
                  for (let [index, obj] of Object.entries(changes)) {
                    console.log(index, obj);
                    dataUpdate[index] = obj.newData;
                  }
                  props.update_report_table_data({
                    [name]: dataUpdate,
                  });
                  setData([...dataUpdate]);
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    props.update_report_table_data({
                      [name]: dataDelete,
                    });
                    setData([...dataDelete]);
                  }
                  resolve();
                }, 500);
              }),
          }}
          style={{
            borderRadius: "18px",
            boxShadow: "none",
            border: "1px solid #ECF1F6",
          }}
          // components={{
          //     Action: props => {
          //         return (
          //             <AddRowAction {...props} />
          //         )
          //     }
          // }}
          actions={
            props.type === "reference"
              ? [
                  {
                    icon: () => (
                      <span style={{ fontSize: "1rem" }}>Edit Column</span>
                    ),
                    tooltip: "Edit Column",
                    isFreeAction: true, // <--- add this to prevent action from taking over row actions
                    onClick: () => {
                      handleClickOpenDialog();
                    },
                  },
                ]
              : []
          }
        />
      </pre>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Edit Column</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the name of the new Column in the field below.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Column Name"
                fullWidth
                inputRef={(el) => (column_field = el)}
              />
            </Grid>
            <Grid item xs={2} style={{ position: "relative" }}>
              <Button
                style={{ position: "absolute", bottom: 0, left: "30%" }}
                variant="contained"
                onClick={handleCreateNewColumn}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <List>
            {columns.map((column, index) => {
              return (
                <ListItem key={`${column.title}-${index}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={column.title} />
                  {/*If they want it to be editable*/}
                  {/*<TextField*/}
                  {/*    id="outlined-basic"*/}
                  {/*    label="Column name"*/}
                  {/*    variant="outlined"*/}
                  {/*    value={column.title}*/}
                  {/*    size="small"*/}
                  {/*/>*/}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteColumn(index, column.field)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(null, {
  update_report_table_data,
  update_reference_doc_store_single,
  delete_reference_doc_store_single,
})(SingleTable);

import { resolve } from "../common_utils/resolve";
import axios from "axios";
import { API_ADDRESSES } from "../../constants/api_addresses";

export const retrieve_text_summarize = async (text, length) => {
  return await resolve(
    axios
      .post(API_ADDRESSES["text_summary"], {
        input_text: text,
        expected_length: length * 1 || 30,
      })
      .then((res) => res.data)
  );
};

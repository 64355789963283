export const HighlightLegend = ({ queries }) => {
  return (
    <div className="flex flex-col items-start">
      <div className="text-xs text-slate-500 font-semibold mb-2">
        Highlight legend
      </div>
      <ul className="text-xs font-medium flex flex-row flex-wrap">
        {queries.map(({ legend, color }, i) => {
          return (
            <li key={i} className="mb-3 mr-2">
              <mark
                style={{ backgroundColor: color }}
                className="py-1 px-2 rounded"
              >
                {legend}
              </mark>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

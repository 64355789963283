import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { rowsPerPage } from "../../constants/types";
import { getDefaultRowOptionValue, getElevationClass } from "../../utils/helper";
import TDCheckbox from "../TDCheckbox";
import TDIcon from "../TDIcon";
import TDIconButton from "../TDIconButton";
import TDLoader from "../TDLoader";
import TDPagination from "../TDPagination";
import { PaginationData } from "../TDPagination/TDPagination.types";
import TDSearchBar from "../TDSearchBar";
import { TableHeadersTypes, TableRowTypes, TDTableProps } from "./TDTable.types";

const TDTable = ({
  headers,
  data,
  className,
  enableDelete,
  elevationLevel = "level_2",
  isCompact = false,
  disablePagination = false,
  disableSearch = false,
  enableMultiSelect = false,
  onPaginate,
  paginationData,
  onSelectChange,
  options,
}: TDTableProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState(paginationData);
  const [selectRows, setSelectRows] = useState({});
  const [toggleAll, setToggleAll] = useState(false);

  const TableRow = (row: TableRowTypes) => {
    const rowData: { [key: string]: any } = row.data;
    const parsedTHRowData = parseRowData(headers[0], rowData[headers[0].id]);

    function parseRowData(head: TableHeadersTypes, value: any) {
      const outputValue =
        head.options != null && head.options.parseValue != null
          ? head.options.parseValue(value, rowData)
          : value;

      return {
        value: outputValue,
        options: {
          className: head.options?.className,
        },
      };
    }

    function onSelect(value): void {
      row?.onSelectChange(value);
    }

    return (
      <tr
        className={clsx(
          "cursor-pointer group hover:bg-slate-150 bg-white even:bg-slate-50 border-b border-slate-150 last:border-none",
          className,
        )}
        data-item={rowData}
        onClick={(e) => {
          if (options != null) {
            options.onRowClick != null && options.onRowClick(rowData.id);
          }
        }}
      >
        {enableMultiSelect && (
          <td className="px-4">
            <TDCheckbox onChange={onSelect} value={row.selectValue} inputOnly />
          </td>
        )}
        <th
          scope="row"
          className={clsx(
            "py-4 font-medium break-words max-w-[8rem] text-primary cursor-pointer hover:underline whitespace-normal",
            isCompact ? "px-3 text-xs" : "px-6",
            parsedTHRowData.options?.className || "",
          )}
          onClick={(e) => {
            if (headers[0].options != null && headers[0].options.onClick != null)
              headers[0].options.onClick(rowData.id, rowData[headers[0].id]);
          }}
        >
          {parsedTHRowData.value}
        </th>
        {headers.map((head, i) => {
          if (i > 0) {
            const parsedRowData = parseRowData(head, rowData[head.id]);
            return (
              <td
                key={`${head.id}-${rowData.id}`}
                data-header={head.id}
                className={clsx("py-4", isCompact ? "px-3 text-xs" : "px-6")}
                onClick={(e) => {
                  if (head.options != null && head.options.onClick != null)
                    head.options.onClick(rowData.id, rowData[head.id]);
                }}
              >
                {parsedRowData.value}
              </td>
            );
          }
        })}

        {enableDelete && (
          <td
            key="delete"
            className="px-6 py-3 ml-auto max-w-[32px] flex flex-row items-center
             justify-center opacity-0 group-hover:opacity-100 transition-opacity ease-out"
          >
            <TDIconButton
              className="mx-auto"
              iconSize="xs"
              variant="ghost"
              btnType="secondary"
            >
              <TDIcon iconSize="xs">
                <TrashIcon />
              </TDIcon>
            </TDIconButton>
          </td>
        )}
      </tr>
    );
  };

  useEffect(() => {
    if (!disablePagination) {
      if (
        paginationData != null &&
        paginationData.pageSize != null &&
        paginationData.pageNum != null
      ) {
        updateInternalPagination(paginationData);
      } else {
        updateInternalPagination({
          pageSize: getDefaultRowOptionValue(),
          pageNum: 1,
        });
      }
    }

    // Initiate empty rows for select values
    if (enableMultiSelect && Object.keys(selectRows).length === 0) {
      const rowValues = {};
      data.forEach((val) => {
        rowValues[val.id] = false;
      });
      setSelectRows(rowValues);
    }
  }, [data]);

  if (enableMultiSelect) {
    useEffect(() => {
      setToggleAll(!Object.values(selectRows).includes(false));
      onSelectChange(selectRows);
    }, [selectRows]);
  }

  function onUpdatePagination(paginationLocal: PaginationData) {
    setPagination(paginationLocal);
    updateInternalPagination(paginationLocal);
    if (onPaginate != null) {
      onPaginate(paginationLocal);
    }
  }

  function setDefPaginationValues() {
    let { pageSize, pageNum, startCount, endCount, totalCount } = pagination;

    setPagination({
      ...paginationData,
      totalCount: totalCount ?? data.length,
      pageNum: pageNum ?? 1,
      startCount: startCount ?? 1,
      endCount: endCount ?? data.length,
      pageSize: pageSize ?? parseInt(rowsPerPage[0].id),
    });
  }

  function updateInternalPagination(pagination: PaginationData) {
    setDefPaginationValues();
    if (data.length >= pagination.pageSize) {
      let calcStartCount = pagination.startCount;
      if (pagination.pageNum == 1) {
        calcStartCount = 1;
      } else if (pagination.pageNum > 0) {
        calcStartCount = (pagination.pageNum - 1) * pagination.pageSize + 1;
      }

      setPagination({
        ...pagination,
        totalCount: paginationData.totalCount,
        startCount: calcStartCount,
        endCount: calcStartCount + (pagination.pageSize - 1),
      });
    }
  }

  function paginatedData(data: Array<TableRowTypes>): Array<TableRowTypes> {
    if (disablePagination) return data;
    return data.slice(
      (pagination.pageNum - 1) * pagination.pageSize,
      pagination.pageNum * pagination.pageSize,
    );
  }

  function onSearchUpdate(value) {
    setSearchValue(value);
  }

  function filteredData(data, value) {
    return data.filter(function (obj) {
      return Object.keys(obj).some(function (key) {
        return obj[key].toString().toLowerCase().includes(value.toLowerCase());
      });
    });
  }

  function onRowSelectChange(value: boolean, id: string) {
    setSelectRows({ ...selectRows, [id]: value });
  }

  function toggleSelectAll(value: boolean) {
    setSelectRows(
      Object.keys(selectRows).reduce((accumulator, key) => {
        return { ...accumulator, [key]: value };
      }, {}),
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      {!disablePagination && (
        <div className="z-10 flex w-full flex-row justify-between space-x-4">
          {!disableSearch && (
            <TDSearchBar
              className="max-w-[14rem] w-full"
              onUpdate={onSearchUpdate}
            />
          )}
          <TDPagination
            className="z-10 ml-auto"
            {...pagination}
            onUpdate={onUpdatePagination}
          />
        </div>
      )}
      <div
        className={clsx(
          "z-0 relative overflow-x-auto flex flex-row items-center sm:rounded-lg border border-slate-100",
          getElevationClass(elevationLevel),
        )}
      >
        {data == null && (
          <TDLoader className="mx-auto w-auto my-2" svgClassName="my-2" />
        )}
        {data != null && (
          <table className="w-full text-sm text-left text-gray-600 ">
            <thead className="text-xs text-slate-600 bg-white border-b border-slate-100">
              <tr>
                {enableMultiSelect && (
                  <th className="px-4">
                    <TDCheckbox
                      id="toggle-header"
                      onChange={toggleSelectAll}
                      value={toggleAll}
                      inputOnly
                    />
                  </th>
                )}
                {headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className={clsx(
                      "px-6 py-3 break-words",
                      i == 0 && "min-w-[6rem] max-w-[14rem] w-[25%]",
                    )}
                  >
                    {header.name}
                  </th>
                ))}
                {enableDelete && (
                  <th key="delete" className="px-6 py-4 max-w-[32px]"></th>
                )}
              </tr>
            </thead>
            <tbody>
              {data.length == 0 && (
                <tr>
                  <td>
                    <div className="px-6 py-4 mx-auto italic text-sm text-slate-400">
                      No item results to show...
                    </div>
                  </td>
                </tr>
              )}
              {paginatedData(filteredData(data, searchValue))?.map((rowData, i) => (
                <TableRow
                  key={rowData.id ?? `row-${i}`}
                  id={rowData.id}
                  data={rowData}
                  onSelectChange={(value: boolean) =>
                    onRowSelectChange(value, rowData.id)
                  }
                  selectValue={selectRows[rowData.id]}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      {!disablePagination && (
        <TDPagination
          className="ml-auto z-10"
          {...pagination}
          onUpdate={onUpdatePagination}
        />
      )}
    </div>
  );
};

export default TDTable;

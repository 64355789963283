import { saveAs } from "file-saver";

export const DownloadAsJson = (summary_file_path, raw_data) => {
  console.log(summary_file_path);
  const filename = summary_file_path.split(
    /summary_json\/|summary_json_edited\//
  )[1];
  let fileToSave = new Blob([JSON.stringify(raw_data)], {
    type: "application/json",
    name: filename,
  });

  try {
    saveAs(fileToSave, filename);
  } catch (e) {
    window.alert("Unable to download JSON file. Please try again later.");
  }
};

import React from "react";
import { TDTable } from "tau-design-system";

export default function DataTable(props) {
  const { columns } = props;
  const rows = props.data;
  const paginationData = {
    totalCount: rows.length,
    pageNum: 1,
    startCount: 1,
  };

  return (
    <div className="w-full">
      <TDTable
        data={rows}
        headers={columns}
        paginationData={paginationData}
        disableSearch
      />
    </div>
  );
}

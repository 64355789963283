import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import ClipboardDocumentListIcon from "@heroicons/react/24/solid/ClipboardDocumentListIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import React, { useState } from "react";
import { TDButton, TDIcon, TDLabelField, TDTextField } from "tau-design-system";
import { retrieve_text_summarize } from "../../APIs/text_summarize_api";

export default function TextSummarize() {
  const defCopyLabel = "Copy Result";
  const [data, setData] = useState({});
  const [query, setQuery] = useState("");
  const [dataState, setDataState] = useState("inactive"); // inactive, loading, error, loaded
  const [textExpLength, setTextExpLength] = useState(30);
  const [copyLabel, setCopyLabel] = useState(defCopyLabel);

  async function summarize(message) {
    try {
      setDataState("loading");
      setData({});
      const { data: resData } = await retrieve_text_summarize(
        message,
        textExpLength
      );
      const formattedResponse = resData.replace(/'/g, '"');
      const parsedData = JSON.parse(formattedResponse);

      if (!_.isEmpty(parsedData[0]["summary_text"])) {
        setData(parsedData[0]["summary_text"]);
        setDataState("loaded");
      } else {
        setDataState("error");
      }
    } catch (error) {
      setDataState("error");
      setData({});
      if (error.response) {
        console.log(error.response);
        console.log("error message: " + error.response.data);
      }
    }
  }

  function checkWordLen(obj) {
    const val = obj.target.value;
    const wordLen = 100;
    var words = val.split(/[\s]+/);

    if (words.length > wordLen) {
      const validWords = words.splice(0, wordLen);
      alert(
        "You've hit the maximum " +
        wordLen +
        " words for the text summary input."
      );
      setQuery(validWords.join(" "));

      return false;
    }

    setQuery(val);

    return true;
  }

  function copyResult() {
    navigator.clipboard.writeText(data);
    setCopyLabel("Copied!");
    setTimeout(() => {
      setCopyLabel(defCopyLabel);
    }, 1500);
  }

  function clearSummary() {
    setQuery(" ");
    setData({});
    setDataState("inactive");
  }

  return (
    <div className="px-4">
      <div>
        <h1 className="font-semibold text-xl">Text Summarization</h1>
      </div>
      <div className="flex flex-col items-start justify-start my-8 w-full max-w-screen-2xl">
        <div className="flex flex-col justify-start items-end w-full">
          <textarea
            name="Clause Recommender Search Text"
            id="clause-recommender-text"
            rows="5"
            className="w-full min-w-[42rem] border border-px border-slate-200 rounded-lg font-medium text-sm px-2 py-1.5"
            value={query} // ...force the input's value to match the state variable...
            onChange={(e) => checkWordLen(e)} // ... and update the state variable on any edits!
          ></textarea>
          <div className="flex flex-row my-4 w-full justify-between">
            <TDLabelField label="Summary word count: ">
              <TDTextField
                onUpdate={setTextExpLength}
                placeholder="30"
                inputClassName="max-w-[2rem]"
              />
            </TDLabelField>
            <div className="flex flex-row space-x-2 justify-end">
              <TDButton variant="ghost" onClick={() => clearSummary()}>
                Clear
              </TDButton>
              <TDButton
                icon={
                  <TDIcon>
                    <MagnifyingGlassIcon />
                  </TDIcon>
                }
                onClick={() => summarize(query)}
              >
                Recommend
              </TDButton>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }} className="w-full">
        {/* <br/> */}
        {dataState === "loading" && (
          <CircularProgress
            data-testid="circular-progress"
            disableShrink={true}
          />
        )}
        {dataState === "error" && (
          <div>Something went wrong. Please try again.</div>
        )}
        {/* <br/> */}
        {!_.isEmpty(data) ? (
          <div className="w-full mt-6">
            <TDLabelField label="Summary Result" labelPos="top">
              <div className="p-4 rounded bg-blue-200 border border-blue-300 text-blue-900 text-sm font-semibold text-left">
                {data}
              </div>
              <TDButton
                onClick={() => copyResult()}
                icon={
                  <TDIcon>
                    <ClipboardDocumentListIcon />
                  </TDIcon>
                }
                variant="ghost"
                className="my-2 ml-auto text-xs flex flex-row"
              >
                {copyLabel}
              </TDButton>
            </TDLabelField>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export const ReportStyle = {
  container_wrapper: {
    display: "flex",
    paddingBottom: "50px",
    maxWidth: "calc(100vw - 9rem)",
  },

  container_left: {
    paddingBottom: "50px",
    left: 0,
    width: "50%",
    transition: "all 200ms ease",
    zIndex: 1,
  },

  container_right: {
    // position: "fixed",
    right: 0,
    width: "50%",
    transition: "all 200ms ease",
  },

  pdf_container_iframe: {
    position: "relative",
    top: 0,
    right: 0,
    width: "100%",
    height: "90vh",
    visibility: "visible",
  },

  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },

  loaderTwo: {
    position: "absolute",
    left: "100%",
    top: "50%",
  },

  verification_icon_button: {
    display: "flex",
    alignItems: "center",
    paddingRight: 0,
    justifyContent: "flex-end",
  },

  button_controls: {
    display: "flex",
    flexDirection: "row",
  },

  published_date: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  expansion_panel_root: {
    width: "100%",
  },

  table_contents: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "blue",
  },
};

import {
  ATTACK_METHODS,
  CAMPAIGN_NAMES,
  COUNTRIES_INVOLVED,
  EMAILS,
  HASHES_MD5,
  HASHES_SHA1,
  HASHES_SHA256,
  IPS,
  MALWARE_CATEGORIES,
  MALWARE_NAMES,
  REGISTRY_KEYS,
  SUMMARY_REPORT,
  TARGET_VERTICALS,
  THREAT_ACTORS,
  TTP,
  URLS,
  VULNERABILITIES,
} from "./tables";

export const SCIM_ELASTIC_MAP = {
  /* Take note of hashes
    Hashes are not under this mapping
    we replace hashes_256 to hashes.256
     */
  [THREAT_ACTORS]: { nested: `${THREAT_ACTORS}.apt` },
  // [GOAL_OF_ATTACK]: {nested: ''},
  [HASHES_MD5]: { nested: "hashes.md5" },
  [HASHES_SHA1]: { nested: "hashes.sha1" },
  [HASHES_SHA256]: { nested: "hashes.sha256" },
  [URLS]: { nested: `${URLS}.url` },
  [EMAILS]: { nested: `${EMAILS}.name` },
  [REGISTRY_KEYS]: { nested: `${REGISTRY_KEYS}.name` },
  [VULNERABILITIES]: { nested: `${VULNERABILITIES}.name` },
  [MALWARE_CATEGORIES]: { nested: `${MALWARE_CATEGORIES}.name` },
  [CAMPAIGN_NAMES]: { nested: `${CAMPAIGN_NAMES}.name` },
  [MALWARE_NAMES]: { nested: `${MALWARE_NAMES}.name` },
  [ATTACK_METHODS]: { nested: `${ATTACK_METHODS}.name` },
  [TTP]: { nested: `${TTP}.name` },
  [IPS]: { nested: `${IPS}.ip` },
  [TARGET_VERTICALS]: { nested: `${TARGET_VERTICALS}.name` },
  [COUNTRIES_INVOLVED]: { nested: `${COUNTRIES_INVOLVED}.name` },
  [SUMMARY_REPORT]: { nested: "filename" },
};

export const REF_DOC_PATH = "/refdoc";
export const REPORT_PATH = "/report_detail";
export const REPORT_REDIRECT_PATH = "/report_redirect";
export const DASHBOARD_PATH = "/dashboard";
export const STIXSEARCH_PATH = "/stixsearch";
export const GRAPHSEARCH_PATH = "/graphsearch";
export const UPLOAD_PATH = "/upload";
export const CONCEPTNETWORK_PATH = "/conceptnetwork";
export const HOME_PATH = "/";
export const NOT_FOUND = "/not-found";
export const DOWNLOAD = "/download";
export const CLAUSE_RECOMMENDER = "/clause-recommender";
export const TEXT_SUMMARIZE = "/text-summarize";
export const TEXT_COMPARISON = "/text-comparison";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import {
  TDAssociativeIcon,
  TDIconButton,
  TDProximityIcon,
  TDSearchAnim,
  TDSearchBar,
} from "tau-design-system";
import { retrieve_search_data } from "../../APIs/freetextsearch_api";
import { objHasKey } from "../../common_utils/util";
import TDDataTable from "./route_component/TDDataTable";
import TDQueryExpansionTable from "./route_component/TDQuery_Expansion_Table";
import { Highlighted } from "./route_component/Highlighted";
import { HighlightLegend } from "./route_component/HighlightLegend";
import randomColor from "randomcolor";
import { sanitizeStopWords } from "../../common_utils/StopWords";

export default class FreeTextSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      query: "",
      query_expansion: {},
      loading: false,
      nomaindata: false,
      noqueryexp: false,
      preciseSearch: false,
      associativeSearch: false,
      proximateSearch: false,
      summaryOnly: false,
      queryList: [],
      originalWord: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePrecise = this.handlePrecise.bind(this);
    this.handleAssociative = this.handleAssociative.bind(this);
    this.handleProximate = this.handleProximate.bind(this);
    this.handleSummary = this.handleSummary.bind(this);

    this.headers = [
      {
        id: "file_name",
        name: "File Name",
        options: {
          parseValue: (value, rowData) => (
            <a
              target="_blank"
              href={`/report_detail?txt_file_path=${encodeURIComponent(
                rowData["txt_file_path"]
              )}&query=${this.state.query}&summary=${
                this.state.summaryOnly
              }&file_path=${encodeURIComponent(rowData["file_path"])}`}
              rel="noopener noreferrer"
            >
              {value}
            </a>
          ),
        },
      },
      {
        id: "agreement_title",
        name: "Document Title",
        options: {
          parseValue: (value, _rowData) => (
            <Highlighted
              text={value}
              query={this.state.originalWord}
              queryList={this.state.queryList}
            />
          ),
        },
      },
      {
        id: "snippet",
        name: "Snippet",
        options: {
          parseValue: (value, _rowData) => (
            <Highlighted
              text={value}
              query={this.state.originalWord}
              queryList={this.state.queryList}
            />
          ),
        },
      },
      {
        id: "date_of_signature",
        name: "Date of Signature",
        options: {
          parseValue: (value, _rowData) => (
            <Highlighted
              text={value}
              query={this.state.originalWord}
              queryList={this.state.queryList}
            />
          ),
        },
      },
    ];
  }

  async search(proximity_search, soft_search) {
    let enable_proximity_search = false;
    let enable_soft_search = false;
    let enable_anatomy_term_expansion = false;
    let enable_partial_search = false;

    this.setState({
      data: [],
      loading: true,
      nomaindata: false,
      noqueryexp: false,
    });

    if (!proximity_search && !soft_search) {
      //activate Precise Search
      console.log("trigger precise search");
      enable_proximity_search = false;
      enable_soft_search = false;
      enable_anatomy_term_expansion = false;
      enable_partial_search = false;
      this.setState({ query_expansion: null });
    }
    if (proximity_search) {
      //activate proximity/proximate search
      console.log("trigger proximity search");
      enable_proximity_search = true;
      enable_soft_search = false;
      enable_anatomy_term_expansion = false;
      enable_partial_search = false;
    }
    if (soft_search) {
      //activate associative search (soft search + anatomy expansion)
      console.log("trigger associative search");
      enable_soft_search = true;
      enable_anatomy_term_expansion = true;
      enable_proximity_search = false;
    }

    let parameters = {
      query: this.state.query,
      enable_anatomy_term_expansion: enable_anatomy_term_expansion,
      enable_soft_search: enable_soft_search,
      enable_partial_search: enable_partial_search,
      enable_proximity_search: enable_proximity_search,
      enable_summary_search: this.state.summaryOnly,
    };

    try {
      const { data } = await retrieve_search_data(parameters);
      this.setState({ loading: false });
      if (_.isEmpty(data["search_results"])) {
        this.setState({
          nomaindata: true,
          noqueryexp: _.isEmpty(data["soft_search_expansion"]),
        });
      } else {
        let { search_results, soft_search_expansion } = data;
        this.setState({
          data: search_results,
          nomaindata: false,
          query_expansion: soft_search_expansion,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        nomaindata: true,
      });
      if (error.response) {
        console.log(error.response);
        console.log("error message: " + error.response.data);
      }
    }
  }

  clearAll() {
    this.setState({
      data: [],
      query_expansion: {},
      queryList: [],
      loading: false,
      nomaindata: false,
      noqueryexp: false,
    });
  }

  handleChange(value) {
    this.setState({ query: value });
  }

  handlePrecise() {
    this.clearAll();
    this.setState({
      preciseSearch: true,
      associativeSearch: false,
      proximateSearch: false,
    });
    this.search(false, false);
  }

  handleAssociative() {
    this.clearAll();
    this.setState({
      preciseSearch: false,
      associativeSearch: true,
      proximateSearch: false,
    });
    this.search(false, true);
  }

  handleProximate() {
    this.clearAll();
    this.setState({
      preciseSearch: false,
      associativeSearch: false,
      proximateSearch: true,
    });
    this.search(true, false);
    this.setState({ noqueryexp: true });
  }

  handleSummary() {
    this.setState({ summaryOnly: !this.state.summaryOnly });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.data !== prevState.data) {
      this.setState({ originalWord: this.state.query });
    }
    if (this.state.query_expansion !== prevState.query_expansion) {
      const queryExpansion = this.state.query_expansion;
      if (!!queryExpansion) {
        const queryList = [];
        Object.keys(queryExpansion).forEach((originalWord) => {
          const associatedWords = queryExpansion[originalWord]?.split(", ");

          if (!!associatedWords && associatedWords.length > 0) {
            const defaultHues = [
              "red",
              "orange",
              "green",
              "blue",
              "purple",
              "pink",
            ];
            let hues = [...defaultHues];

            associatedWords.forEach((word) => {
              const hue = hues[_.random(0, hues.length - 1)];
              const query = sanitizeStopWords(word);

              _.remove(hues, (x) => x === hue);

              if (hues.length === 0) {
                hues = [...defaultHues];
              }

              queryList.push({
                legend: word,
                query,
                color: randomColor({
                  hue,
                  luminosity: "light",
                }),
              });
            });

            this.setState({ queryList });
            console.log(queryList);
          }
        });
      }
    }
  }

  componentDidMount() {
    if (objHasKey(this.props, "search")) {
      if (!_.isEmpty(this.props.location.search)) {
        const search_query = queryString.parse(
          this.props.location.search
        ).search;

        // Trigger precise search after setting state
        this.setState({ query: search_query }, () => {
          this.handlePrecise();
        });
      }
    }
  }

  render() {
    return (
      <div className="max-h-[calc(100vh-5rem)] flex flex-col justify-start h-full w-full py-20 px-12">
        <div className="flex flex-row justify-between items-start space-x-10">
          <div className="flex flex-col w-full items-center justify-center">
            <div className="flex flex-col space-y-4 items-center justify-center">
              {_.isEmpty(this.state.data) && <TDSearchAnim />}
              <h1 className="text-2xl font-semibold text-slate-900">
                Intelligent search starts here.
              </h1>
            </div>
            <div className="w-full flex flex-row justify-center items-center">
              <TDSearchBar
                className="z-10 w-full my-14 max-w-[42rem] font-open-sans"
                onUpdate={this.handleChange}
                onEnter={this.handlePrecise}
                value={this.state.query}
                actions={
                  <>
                    <TDIconButton
                      onClick={this.handleAssociative}
                      iconSize="xs"
                      tooltip="Associative Search"
                      preventDefault
                    >
                      <TDAssociativeIcon className="text-white h-5 w-5" />
                    </TDIconButton>
                    <TDIconButton
                      onClick={this.handleProximate}
                      iconSize="xs"
                      tooltip="Proximity Search"
                      preventDefault
                    >
                      <TDProximityIcon className="text-white h-5 w-5" />
                    </TDIconButton>
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          {/* <br/> */}
          {this.state.loading && (
            <CircularProgress
              data-testid="circular-progress"
              disableShrink={true}
            />
          )}
          {this.state.nomaindata && this.state.noqueryexp && (
            <div>No Results Returned</div>
          )}

          {!_.isEmpty(this.state.query_expansion) ? (
            <div className="mb-10 flex flex-col space-y-4 justify-start items-start">
              <h3 className="text-md text-slate-500 font-semibold">
                Query Expansion
              </h3>
              <TDQueryExpansionTable
                data={this.state.query_expansion}
                queryList={this.state.queryList}
              />
            </div>
          ) : null}

          {/* <br/> */}
          {!_.isEmpty(this.state.data) ? (
            <div className="flex flex-col space-y-4 justify-start items-start search-table-wrap">
              <h3 className="text-md text-slate-500 font-semibold">
                Search Results
              </h3>
              <HighlightLegend
                queries={[
                  {
                    legend: this.state.originalWord,
                    query: sanitizeStopWords(this.state.originalWord),
                    color: "#FFFF00",
                  },
                  ...this.state.queryList,
                ]}
              />
              <TDDataTable
                role="table"
                data={this.state.data}
                columns={this.headers}
                query={this.state.query}
                summaryOnly={this.state.summaryOnly}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
